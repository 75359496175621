
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ToastMessage } from '../../../Components/Common/ToastMessage';

interface waitingRoomPatientDataArgs {
    EndPoint: string;
    userId: string;
    hospital_identifier_id: string;
    authToken: string;
}

// Define the return type for the Thunk action
interface waitingRoomPatientDataMessages {
    messages: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const waitingRoomPatientDataThunk = createAsyncThunk<waitingRoomPatientDataMessages, waitingRoomPatientDataArgs, { state: RootState }>(
    'provider/waitingRoomPatientData',
    async ({ EndPoint, userId, hospital_identifier_id, authToken }, { dispatch }) => {
        try {
            let ApiEndPoint;
            let url;
            switch (EndPoint) {
                case "PatientDemographics":
                    url = ApiUrl.profile.get_patient_demographics_data
                    break;
                case 'AllergiesAndIntolerances':
                    url = ApiUrl.profile.get_allergies_intolerance_data
                    break;
                case 'Vaccinations':
                    url = ApiUrl.profile.get_vaccinations_data
                    break;
                case 'SmokingStatus':
                    url = ApiUrl.profile.get_smoking_status_data
                    break;
                case 'CareTeams':
                    url = ApiUrl.care_teams.get_care_teams_data
                    break;
                case 'Providers':
                    url = ApiUrl.care_teams.get_practitioner_teams_data
                    break;
                case 'Conditions':
                    url = ApiUrl.care_history.get_conditions_data
                    break;
                case 'Encounters':
                    url = ApiUrl.care_history.get_encounters_data
                    break;
                case 'TreatmentPlan':
                    url = ApiUrl.care_history.get_treatment_plan_data
                    break;
                case 'Medications':
                    url = ApiUrl.medications.get_medication_data
                    break;
                case 'Procedures':
                    url = ApiUrl.procedures.get_procedures_data
                    break;
                // case 'Devices':
                //     Component = PatientDemographics;
                //     break;
                // case 'HealthScreen':
                //     Component = PatientDemographics;
                //     break;
                // case 'LabPanels':
                //     Component = PatientDemographics;
                //     break;
                // case 'Vitals':
                //     Component = PatientDemographics;
                //     break;

                default:
                    break;
            }
            ApiEndPoint = `${url}?userId=${userId
                }&hospital_identifier_id=${hospital_identifier_id}`;
            const response = await get(ApiEndPoint, authToken);
            if (response.status === 200) {
                return response.data;
            } else {
                ToastMessage("error", "Something went Wrong")
                throw new Error('Failed to Send Email');
            }
        } catch (error) {
            ToastMessage("error", "Something went Wrong")
            throw error;
        }
    }
);
