import React, { useEffect, useRef, useState } from 'react'

//components
import CommonHeader from '../../Platform/Header/CommonHeader';
import Sidebar from '../../Platform/Sidebar/Sidebar';
import SelectTypeHeader from '../../Platform/Header/SelectTypeHeader';
import LaptopComponent from './LaptopComponent';
import LaptopBlackSidebar from '../../Platform/Sidebar/LaptopBlackSidebar/LaptopBlackSidebar';

import SketchPicker from "react-color";

// redux
import { RootState } from '../../../../../Store';
import { useDispatch, useSelector } from 'react-redux';
import { resetOnBoarding, setheaderTextvalue, setIsFinishedLogin, setSelectedPrimaryColor, setSelecteSecondaryColor, setTextColor, setType } from '../../../../../Store/Slicers/OnBoarding/OnBoardingSlicer';

//css
import '../../Platform/Header/CommonHeader.scss'
import '../../../ClinicOnBoarding.scss';
import { getLocalStorage } from '../../../../../Components/Patient/Utils/AuthHandlers';
import { storeIndividualProvider } from '../../../../../Store/Thunk/Onboarding/storeIndividualProviderThunk';
import { toast } from 'react-toastify';
import WarningModel from '../../Platform/Sidebar/LaptopBlackSidebar/Helpers/WarningModel';
import { ToastMessage } from '../../../../../Components/Common/ToastMessage';

interface Step4Props {
    handleBackButtonClick: () => void;
    handleCheckoutButtonClick: () => void;
    planName?: 'individual' | 'clinic';
}

const Step4 = ({ handleBackButtonClick, handleCheckoutButtonClick, planName }: Step4Props) => {
    const { isfinishedSaving, selectedType } = useSelector((state: RootState) => state.onBoarding)
    const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)
    const { textColor, headerText } = useSelector((state: RootState) => state.onBoarding.serviceValues)
    const dispatch = useDispatch()


    const [isPrimaryPickerOpen, setIsPrimaryPickerOpen] = useState(false);
    const [isSecondaryPickerOpen, setIsSecondaryPickerOpen] = useState(false);
    const [PrimaryColor, setPrimaryColor] = useState(selectedPrimaryColor)
    const [SecondaryColor, setSecondaryColor] = useState(selectedSecondaryColor)

    const userData = getLocalStorage('userData')
    const data = getLocalStorage('data')
    const authToken = getLocalStorage('userToken')
    const admin = getLocalStorage('admin')
    const provider = getLocalStorage('provider')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStartedVisible, setIsStartedVisible] = useState(true)
    const [index, setIndex] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false)

    const [isTextPickerOpen, setisTextPickerOpen] = useState(false);

    const colorPickerRef = useRef<HTMLDivElement>(null);

    const [title, setTitle] = useState<"Standard Platform" | "Customize" | null>(null)

    const handleModalOpen = () => {
        setTitle('Customize')
        setIsModalOpen(true);
    }
    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const handleSaveData = async () => {
        toast.loading('Please wait...')
        try {
            const payload = {
                account_id: userData.sub,
                email: data.email,
                title: 'Mr.',
                first_name: data.firstName,
                last_name: data.lastName,
                preferred_name: data.firstName + ' ' + data.lastName,
                provider_acknowledgement: data.provider_acknowledgement,
                terms_agreement_acknowledgement: data.terms_agreement_acknowledgement,
                platform_type: title === 'Standard Platform' ? "STANDARD" : "CUSTOMIZED",
                service_name: headerText,
                text_color_code: textColor || "#000",
                primary_color: selectedPrimaryColor,
                secondary_color: selectedSecondaryColor,
                admin: admin ? admin : [],
                provider: provider ? provider : [],
            }
            const response: any = await dispatch(storeIndividualProvider({ data: payload, authToken, plan: planName }))
            if (response.payload.status === 200) {

                toast.dismiss();
                ToastMessage("success", 'Data saved successfully')
                dispatch(setType('Patient'))
                handleCheckoutButtonClick()
            }
        } catch (error) {
            toast.dismiss();
            ToastMessage("error", 'Something went wrong')
            console.log("🚀 ~ handleSaveData ~ error:", error)
        }
    }

    // useEffect(() => {
    //     const handleClickOutside = (event: any) => {
    //         if (isModalOpen && event.target.classList.contains('laptop-model')) {
    //             handleModalClose();
    //         }
    //     };

    //     document.addEventListener('click', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, [isModalOpen]);

    useEffect(() => {
        if (isfinishedSaving && !isModalOpen) {
            setIsStartedVisible(false)
        }
    }, [isfinishedSaving])

    const handleTypeChange = (type: string) => {
        dispatch(setType(type))
    }

    const standardPlatform = () => {
        setTitle("Standard Platform")
        dispatch(setIsFinishedLogin(true))
    }

    const handleButtonClick = () => {
        setTitle("Customize")
        setIsModalOpen(true)
    }

    const handleColorChange = (color: any, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)
        const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`
        dispatch(setTextColor(hexCode));
        setisTextPickerOpen(true);
    };


    const handlePrimaryColor = (color: any, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)
        const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`
        setPrimaryColor(hexCode);
        dispatch(setSelectedPrimaryColor(hexCode))
        setIsPrimaryPickerOpen(true);
    };

    const handleSecondaryColor = (color: any, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)
        const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`
        setSecondaryColor(hexCode);
        dispatch(setSelecteSecondaryColor(hexCode))
        setIsSecondaryPickerOpen(true);
    };

    const handlePickerOpen = () => {
        setisTextPickerOpen(true);
    };

    const handleChange = (e: any) => {
        dispatch(setheaderTextvalue(e.target.value))
    }

    const handleNextClick = () => {
        setIndex(index + 1);
        if (index === 1) {
        } else if (index > 1) {
            dispatch(setIsFinishedLogin(true))
            handleModalClose()
            setIndex(1)
        }
    }

    const handlePrevClick = () => {
        setIndex(index - 1)

        if (index === 1) {
            dispatch(resetOnBoarding())
            dispatch(setIsFinishedLogin(false))
            handleModalClose()
            setIndex(1)
        }
    }


    const handleRevert = () => {
        setShowModal(true)
    }

    const handleConfirm = () => {
        dispatch(resetOnBoarding())
        dispatch(setType("Patient"))
        setIndex(1);
        setShowModal(false)
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const primaryColorBoxRef = useRef<HTMLDivElement>(null);
    const secondaryColorBoxRef = useRef<HTMLDivElement>(null);

    const handlePicker = (value: 'primary' | 'secondary') => {
        switch (value) {
            case 'primary':
                setIsPrimaryPickerOpen(true);
                break;
            case 'secondary':
                setIsSecondaryPickerOpen(true);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setisTextPickerOpen(false);
            }
            if (
                primaryColorBoxRef.current && !primaryColorBoxRef.current.contains(event.target)
            ) {
                setIsPrimaryPickerOpen(false);
            }
            if (
                secondaryColorBoxRef.current && !secondaryColorBoxRef.current.contains(event.target)
            ) {
                setIsSecondaryPickerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='w-100'>
            <div className={`onboarding-main-flow ${isModalOpen ? "laptop-modal-parent" : "d-flex "}`} style={{ marginTop: "15px" }}>
                <div className='d-flex flex-column step-4' style={isfinishedSaving ? {} : { gap: "60px" }}>
                    <div className='d-flex flex-column gap-3 align-items-center'>
                        {/* <p className='p-top'>
                            STEP 4
                        </p> */}
                        {isModalOpen &&
                            <>
                                <div className='row laptop-modal-body'>
                                    <div className="h-100 p-0">
                                        <div className="inner-screen outer-height position-relative" id="bg-ring">
                                            <div className='laptop-notch'></div>
                                            <div className='image'>

                                            </div>
                                            {/* <SelectTypeHeader planName={planName} /> */}
                                            <CommonHeader className={index === 2 ? 'blurred' : ''} />
                                            <Sidebar ComponentTitle='Customize' className={index === 1 ? 'blurred' : ''} />
                                            <div className='position-absolute new-laptop-modal' style={{ width: "65%" }}>
                                                <div className='main-laptop-modal'>
                                                    {index === 1 ?
                                                        <>
                                                            <h1 className='new-laptop-header'>What would you like to name your telehealth service?</h1>
                                                            <p className='name-examples' >
                                                                Examples: <br />
                                                                <span>
                                                                    <ul className='ms-4'>
                                                                        <li>Canyon Park Virtual Care or Mission Clinic Hybrid Health or Maple Primary Telehealth</li>
                                                                    </ul>
                                                                </span>
                                                            </p>
                                                            <div className='w-100'>
                                                                <label className='fw-bold' style={{ marginBottom: "6px", fontSize: "14px" }}>Service Name</label>
                                                                <div className='d-flex justify-content-between gap-4 w-100'>
                                                                    <input type="text" className='w-100 service-input' placeholder={headerText} value={headerText} onChange={handleChange} onClick={() => dispatch(setheaderTextvalue(""))} />
                                                                    <div className="text-color-box d-flex justify-content-between align-items-center position-relative w-100">
                                                                        <p className="heading-text align-middle">Text Color</p>
                                                                        <div className='color-box ' style={{ backgroundColor: textColor ? textColor : "#000", width: "30px" }} ref={colorPickerRef} onClick={handlePickerOpen}>
                                                                            {isTextPickerOpen &&
                                                                                <div className="color-picker-container position-absolute">
                                                                                    <SketchPicker
                                                                                        className='first-content-picker'
                                                                                        color={textColor}
                                                                                        onChange={handleColorChange}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <h1 className='new-laptop-header'>Let’s pick your Primary and Secondary colors for telehealth service?</h1>
                                                            <div className='w-100'>
                                                                <label className='fw-bold' style={{ marginBottom: "6px", fontSize: "10px" }}>Platform Colors</label>
                                                                <div className='d-flex justify-content-between gap-4 w-100'>
                                                                    <div className="text-color-box d-flex justify-content-between align-items-center position-relative w-100">
                                                                        <p className="heading-text align-middle">Select Primary Color</p>
                                                                        <div className='color-box position-relative' style={{ backgroundColor: PrimaryColor ? PrimaryColor : "#3B8FA9", width: "30px" }} ref={primaryColorBoxRef} onClick={() => handlePicker("primary")}>
                                                                            {isPrimaryPickerOpen &&
                                                                                <div className="color-picker-container position-absolute" >
                                                                                    <SketchPicker
                                                                                        className='first-content-picker'
                                                                                        color={PrimaryColor}
                                                                                        onChange={handlePrimaryColor}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-color-box d-flex justify-content-between align-items-center position-relative w-100">
                                                                        <p className="heading-text align-middle">Select Secondary color</p>
                                                                        <div className='color-box ' style={{ backgroundColor: SecondaryColor ? SecondaryColor : "#E2EAED", width: "30px" }} ref={secondaryColorBoxRef} onClick={() => handlePicker("secondary")}>
                                                                            {isSecondaryPickerOpen &&
                                                                                <div className="color-picker-container position-absolute">
                                                                                    <SketchPicker
                                                                                        className='first-content-picker'
                                                                                        color={SecondaryColor}
                                                                                        onChange={handleSecondaryColor}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className='d-flex justify-content-between w-100 laptop-modal-btns' >
                                                        <button className='new-laptop-modal-btn' onClick={handleRevert}>Revert all Changes</button>
                                                        <div className='d-flex gap-2 w-50'>
                                                            <button className='new-laptop-modal-btn w-50' onClick={handlePrevClick}>Back</button>
                                                            <button className='yes-theme-btn w-50 px-0' onClick={handleNextClick}>Next</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className="col-xl-3 col-md-4 p-0 outer-black-sidebar">
                                        <LaptopBlackSidebar handleModalClose={handleModalClose} />
                                    </div> */}
                                </div>
                            </>}
                        <div className={`text-center ${isModalOpen && 'd-none'}`}>

                            <h1 className='onboarding-heading'>{isfinishedSaving ? "Let's look at your platform" : "Use Our Standard Platform or Customize Your Own"}</h1>
                            {
                                !isfinishedSaving &&
                                <p className='onboarding-detail' style={{ color: "#3B8FA9" }}>A branded telehealth platform demonstrates innovation, enhances the patient experience, and strengthens trust in your healthcare organization.</p>
                            }
                        </div>
                    </div>

                    <div className={`d-flex gap-3 flex-column justify-content-center ${isModalOpen && 'd-none'}`}>
                        <div className='position-relative'>
                            {
                                isfinishedSaving &&
                                <div className='d-flex justify-content-center gap-4 select-type'>
                                    <p className={`type ${selectedType === "Patient" && 'active-type'}`} onClick={() => handleTypeChange("Patient")}>Patient</p>
                                    {planName === 'individual' && <p className={`type ${selectedType === "Provider" && 'active-type'}`} onClick={() => handleTypeChange("Provider")}>Provider</p>}
                                    {planName === 'clinic' &&
                                        <>
                                            <p className={`type ${selectedType === "Provider" && 'active-type'}`} onClick={() => handleTypeChange("Provider")}>Provider</p>
                                            <p className={`type ${selectedType === "Admin" && 'active-type'}`} onClick={() => handleTypeChange("Admin")}>Admin</p>
                                        </>
                                    }
                                </div>
                            }
                            <div className='d-flex justify-content-center flex-column position-relative'>
                                {
                                    !isfinishedSaving && !isModalOpen &&
                                    <>

                                        {/* <div className='d-flex position-absolute' style={{ bottom: 0 }}>
                                            <button className='onboarding-back main-delete-theme-btn py-2' style={{ borderRadius: "100px" }} onClick={handleBackButtonClick}>Back</button>
                                        </div> */}

                                        <div className='d-flex gap-4 step4-two-laptop-screen'>
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Standard Platform" />
                                                <div className='d-flex justify-content-center'>
                                                    <button className='customize-theme-btn' onClick={standardPlatform}>Standard Platform</button>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Customize" />
                                                <div className='d-flex justify-content-center'>
                                                    <button className='customize-theme-btn' onClick={handleModalOpen}>Customize</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='d-flex position-absolute' style={{bottom:-2}}>
                                            <button className='onboarding-back main-delete-theme-btn' onClick={handleBackButtonClick}>Back</button>
                                        </div> */}
                                    </>
                                }
                                {
                                    isfinishedSaving &&
                                    (
                                        title === "Standard Platform" ?
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Standard Platform" />
                                            </div> : <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Customize" />
                                            </div>
                                    )
                                }
                            </div>

                            {
                                isfinishedSaving &&
                                <div className='dot-button-content w-100'>
                                    <div className='three-dots mt-4 d-flex gap-2 justify-content-center '>
                                        <div className={`dots ${selectedType === "Patient" && 'filled'}`}></div>
                                        {planName === 'individual' && <div className={`dots ${selectedType === "Provider" && 'filled'}`}></div>}
                                        {planName === 'clinic' &&
                                            <>
                                                <div className={`dots ${selectedType === "Provider" && 'filled'}`}></div>
                                                <div className={`dots ${selectedType === "Admin" && 'filled'}`}></div>
                                            </>
                                        }
                                    </div>
                                    <br />
                                    <div className='mt-2 d-flex justify-content-center p-2 gap-4'>
                                        <button className='btn pro-light-btn' style={{ background: "#fff" }} onClick={handleButtonClick}>Try Customizing</button>
                                        <button className='onboarding-checkout' onClick={handleSaveData} style={{ borderRadius: "109px" }}>Confirm and Continue</button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </div>
            {showModal && <WarningModel showModal={showModal} closeModal={closeModal} handleConfirm={handleConfirm} />}
        </div>
    )
}

export default Step4