import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { forwardRef, useEffect, useState, useRef } from "react";
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  DefaultModality,
  DefaultActiveSpeakerPolicy,
  DefaultVideoTile,
  BackgroundBlurVideoFrameProcessor,
  BackgroundBlurConfiguration,
  BackgroundBlurStrength,
  DefaultVideoTransformDevice,
  BackgroundReplacementVideoFrameProcessor,
} from "amazon-chime-sdk-js";
// import MicOffIcon from "@mui/icons-material/MicOff";
// import MicIcon from "@mui/icons-material/Mic";
import { SectionBox, InvisibleAudio } from "./ui-component";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { getLocalStorage } from "../../Utils/AuthHandlers";
import { DefaultImageGallery, MicFill, MicOffIcon, MicPermission, moreIcon } from "../../../../Assets/Svgs";
import images from "../../../../Assets/Images";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { setAllNotes, setFullScreen, setIsMeetingEnded, setSlider, setStartChat, setStartVideoCall } from "../../../../Store/Slicers/commonSlicer";
import html2pdf from 'html2pdf.js'

//css
import './commonUI.scss'
import { storeMeetingNotes } from "../../../../Store/Thunk/Meeting/storeMeetingNotesThunk";
import { getInitials, lightenColor } from "../../../utils";
import { ToastMessage } from "../../../Common/ToastMessage";

const Meeting = (props) => {

    // get this three things form props room, room_id, userId
  const userId = getLocalStorage("userData").sub;
  const room_id = getLocalStorage("room_id");
  const room = props.room ? props.room : room_id;
  const meeting_id = localStorage.getItem('meeting_id')
  const dispatch = useDispatch()

  // get this from websocket
  const { id } = useParams();

  const [meetingSession, setMeetingSession] = useState(null);
  const [joining, setJoining] = useState("");
  const [hasStartedMediaInputs, setStartedMediaInputs] = useState(false);
  const [hadFinishedApplication, setFinishedApplication] = useState(false);
  const [isVideoShow, setIsVideoShow] = useState(true);

  const handleJoin = (joining) => {
    setJoining(joining.room);
    createMeetingSession(joining).then((it) => setMeetingSession(it));
  };

  const toggleVideoOnOff = async ()=>{
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
  
    if (isVideoShow) {
      // Turn off video
      await meetingSession.audioVideo.stopVideoInput(); 
      meetingSession.audioVideo.stopLocalVideoTile();
    } else {
      // Turn on video
      await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
      meetingSession.audioVideo.startLocalVideoTile();
    }
  
    setIsVideoShow(!isVideoShow)
  }

  const videoRef = useRef(null);
  useEffect(() => {
    if (!meetingSession) {
      return;
    }

    meetingSession.audioVideo.startLocalVideoTile();

    const setupInput = async ({ audioId, videoId } = {}) => {
      // Check if both audioId and videoId are provided
      if (!audioId || !videoId) {
        throw new Error("No video nor audio input detected.");
      }

      // Setting up audio input
      if (audioId) {
        // List available audio input devices
        const audioInputDevices =
          await meetingSession.audioVideo.listAudioInputDevices();
        // Check if there are available audio input devices
        if (audioInputDevices.length) {
          // Get the deviceId of the first audio input device
          const audioDevices = audioInputDevices[0].deviceId;
          // Choose audio output based on provided audioId or default to the first device
          await meetingSession.audioVideo.chooseAudioOutput(
            audioId === "default" ? audioDevices : audioId
          );
          // Start audio input based on provided audioId or default to the first device
          await meetingSession.audioVideo.startAudioInput(
            audioId === "default" ? audioDevices : audioId
          );
        }
      }

      if (videoId) {
        // List available video input devices
        const videoInputDevices =
          await meetingSession.audioVideo.listVideoInputDevices();
        // Check if there are available video input devices
        if (videoInputDevices.length) {
          // Get the deviceId of the first video input device
          const defaultVideoId = videoInputDevices[0]?.deviceId;
          // Start video input based on provided videoId or default to the first device
          await meetingSession.audioVideo.startVideoInput(
            videoId === "default" ? defaultVideoId : videoId
          );
          // Set the flag indicating that media inputs have started
          setStartedMediaInputs(true);
        }
      }
    };

    // Assuming you're setting up media tracks for your Chime SDK meeting

    setupInput({ audioId: "default", videoId: "default" }).then(() => {
      const observer = {
        audioInputsChanged: (freshAudioInputDeviceList) => {
          // An array of MediaDeviceInfo objects
          freshAudioInputDeviceList.forEach((mediaDeviceInfo) => {
            console.log(
              `Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`
            );
          });
        },

        audioOutputsChanged: (freshAudioOutputDeviceList) => {
          console.log("Audio outputs updated: ", freshAudioOutputDeviceList);
        },

        videoInputsChanged: (freshVideoInputDeviceList) => {
          console.log("Video inputs updated: ", freshVideoInputDeviceList);
        },

        audioInputMuteStateChanged: (device, muted) => {
          console.warn(
            "Device",
            device,
            muted ? "is muted in hardware" : "is not muted"
          );
        },
      };
      meetingSession.audioVideo.addDeviceChangeObserver(observer);

      meetingSession.audioVideo.start();

      const activeSpeakerCallback = (attendeeIds) => {
        if (!attendeeIds || !attendeeIds.length) {
          return;
        }

        const mostActiveAttendeeId = attendeeIds[0];
        const mostActiveAttendeeElement = document.getElementById(
          `video-${mostActiveAttendeeId}`
        );
        copyStreamToPinnedVideo(mostActiveAttendeeElement);
      };

      meetingSession.audioVideo.subscribeToActiveSpeakerDetector(
        new DefaultActiveSpeakerPolicy(),
        activeSpeakerCallback
      );
    });
  }, [meetingSession]);

  const isInSession = !!(meetingSession && hasStartedMediaInputs);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const videoElement = videoRef.current;

    const observer = {
      videoTileDidUpdate: (tileState) => {
        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent
        ) {
          return;
        }
        meetingSession.audioVideo.bindVideoElement(
          tileState?.tileId,
          videoElement
        );
      },
    };
    meetingSession.audioVideo.addObserver(observer);
    meetingSession.audioVideo.start();
  }, [meetingSession]);

  const logger = new ConsoleLogger("SDK", LogLevel.INFO);
  const deviceController = new DefaultDeviceController(logger);

  useEffect(() => {
    createMeetingSession().then((it)=>setMeetingSession(it))
  }, [])
  

  async function createMeetingSession() {
    let meetingResponse;
    let attendeeResponse;

    try {
        if (meeting_id && props.type === 'patient') {
          await document.getElementById('avatar-frame')?.remove()
            const response = await axios.get(
                `https://2mcs9gz9e2.execute-api.us-east-1.amazonaws.com/prod/meeting/${meeting_id}?userType=PATIENT&userId=${props.localUserData.user_id}`
            );

            if (response && response.data) {
                meetingResponse = response.data.meetingResponse;
                attendeeResponse = response.data.attendee;
            } else {
                throw new Error("Invalid response structure");
            }
        } else if (props.type === 'admin') {
            const response = await axios.post(
                `https://2mcs9gz9e2.execute-api.us-east-1.amazonaws.com/prod/aws-chime-meeting?room=${props.remoteUserData?.user_id}&creator_type=ADMIN&admin_id=${props.admin_id}&provider_id=${props.provider_id}&patient_id=${props.remoteUserData?.user_id}`,
                {
                    creator_type: 'ADMIN',
                    admin_id: props.admin_id,
                    provider_id: props.provider_id,
                    patient_id: props.remoteUserData?.user_id
                }
            );

            meetingResponse = response?.data?.meetingResponse;
            attendeeResponse = response?.data?.attendee?.adminAttendee;

            const message = {
                action: "meetingDetails",
                to_user_id: props.remoteUserData?.user_id,
                from_user_id: props.admin_id,
                meetingConfigs: meetingResponse?.Meeting?.MeetingId
            };
            props.socket?.send(JSON.stringify(message));
        } else {
            const response = await axios.post(
                `https://2mcs9gz9e2.execute-api.us-east-1.amazonaws.com/prod/aws-chime-meeting?room=${props.patient_id}&creator_type=PROVIDER&provider_id=${props.provider_id}&patient_id=${props.patient_id}`,
                {
                    creator_type: 'PROVIDER',
                    provider_id: props.provider_id,
                    patient_id: props.patient_id
                }
            );

            meetingResponse = response?.data?.meetingResponse;
            attendeeResponse = response?.data?.attendee?.providerAttendee;

            const message = {
                action: "meetingDetails",
                to_user_id: props.patient_id,
                from_user_id: props.provider_id,
                meetingConfigs: meetingResponse?.Meeting?.MeetingId
            };
            props.socket?.send(JSON.stringify(message));
        }

        const configuration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
        const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
        return meetingSession;

    } catch (error) {
        console.error("Error in createMeetingSession:", error);

        // Handle different error types
        if (axios.isAxiosError(error)) {
            // Handle Axios-specific errors
            ToastMessage("error", `Network error: ${error.message}`);
            dispatch(setStartVideoCall(false))
            dispatch(setSlider(true))
          } else if (error.response) {
            // The request was made and the server responded with a status code
            ToastMessage("error", `Error: ${error.response.status} - ${error.response.data.message || error.message}`);
            dispatch(setStartVideoCall(false))
            dispatch(setSlider(true))
          } else {
            // Something happened in setting up the request
            ToastMessage("error", `Error: ${error.message}`);
            dispatch(setStartVideoCall(false))
            dispatch(setSlider(true))
        }
    }
}

  return (
      <div className={`h-100 w-100 `} style={{ position: "relative", overflow: "hidden" }}>
      {/* <MainHeader meetingId={meetingId} /> */}
      {/* {!hadFinishedApplication && !isInSession && id && (
        <Container maxWidth="xs">
          <MainJoiningMeeting onJoin={handleJoin} />
        </Container>
      )}
      {!hadFinishedApplication && !isInSession && !joining && !id && (
        <Container maxWidth="xs">
          <MainJoiningMeeting onJoin={handleJoin} />
        </Container>
      )}
      {!hadFinishedApplication && !isInSession && joining && (
        <Container maxWidth="xs">
          <SectionBox heading="Joining...">
            Attempting to join <code>{joining}</code> meeting.
          </SectionBox>
        </Container>
      )}
      {hadFinishedApplication && (
        <Container maxWidth="xs">
          <SectionBox heading="Bye, bye!">
            You can close this window now or...{" "}
            <Button variant="text" onClick={() => window.location.reload()}>
              start another meeting
            </Button>
          </SectionBox>
        </Container>
      )} */}
      {/* <PinnedVideoSection /> */}

      {!hadFinishedApplication && isInSession && (
        <>
          <StreamingVideosSection 
            meetingSession={meetingSession}
            remoteUserData={props.remoteUserData}
            localUserData={props.localUserData}
            isVideoShow={isVideoShow}/>

          <AudioOutput meetingSession={meetingSession} />

          <Controls
            meetingSession={meetingSession}
            room={meeting_id}
            onLeave={() => setFinishedApplication(true)}
            type={props.type}
            toggleVideoOnOff={toggleVideoOnOff}
            isVideoShow={isVideoShow}
          />
        </>
      )}
      </div>
  );
};

// function MainHeader({ meetingId }) {
//   const [isCopied, setIsCopied] = useState(false);

//   // get room from websocket
//   const meetingUrl = `https://16ee-2401-4900-1f3f-d70e-3171-fead-5fd1-cd1d.ngrok-free.app/meeting/${meetingId}`;

//   const copyToClipboard = async () => {
//     try {
//       await navigator.clipboard.writeText(meetingUrl);
//       setIsCopied(true);
//     } catch (error) {
//       console.error("Unable to copy to clipboard", error);
//     }
//   };
//   const boxStyle = {
//     boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)", // Adjust values as needed
//     padding: "20px",
//   };
//   return (
//     <Box component="header" textAlign="center">
//       <Typography component="h1" variant="h4">
//         Amazon Chime SDK App
//         {meetingId ? (
//           <div style={boxStyle}>
//             <Link
//               to={meetingUrl}
//               style={{ fontSize: "50%", marginRight: "17px" }}
//             >
//               {meetingUrl}
//             </Link>
//             <button onClick={copyToClipboard}>
//               {isCopied ? "Link Copied!" : "Copy Link"}
//             </button>
//           </div>
//         ) : (
//           <></>
//         )}
//       </Typography>
//     </Box>
//   );
// }

function PinnedVideoSection() {
  const videoRef = useRef(null);

  useEffect(() => {
    const workerId = setInterval(() => {
      if (videoRef.current.srcObject && videoRef.current.srcObject.active) {
        return;
      }

      const foundActiveStreamingElement = Array.from(
        document.getElementsByClassName("streaming-video")
      ).find((el) => el.srcObject && el.srcObject.active);
      copyStreamToPinnedVideo(foundActiveStreamingElement, videoRef.current);
    }, 3000);
    return () => clearInterval(workerId);
  }, []);

  return (
    <Video
      ref={videoRef}
      id="video-pinned"
      aria-label="Pinned video"
      style={{ maxHeight: "80vh", objectFit: "contain" }}
      width={undefined}
      height={undefined}
    />
  );
}

function copyStreamToPinnedVideo(originatingVideoElement,pinnedVideoElement = document.getElementById("share-video")) {
  if (!originatingVideoElement || !originatingVideoElement.srcObject) {
    console.error(
      "Invalid originating video element/stream",
      originatingVideoElement
    );
    return;
  }

  if (!pinnedVideoElement) {
    console.error("Invalid pinned video element", pinnedVideoElement);
    return;
  }

  if (pinnedVideoElement.srcObject === originatingVideoElement.srcObject) {
    return;
  }

  pinnedVideoElement.muted = true;
  pinnedVideoElement.volume = 0;
  pinnedVideoElement.setAttributeNode(document.createAttribute("autoplay"));
  pinnedVideoElement.setAttributeNode(document.createAttribute("playsinline"));
  pinnedVideoElement.srcObject = originatingVideoElement.srcObject;
}

// function MainJoiningMeeting({ onJoin }) {
//   const handleSubmit = (event) => {
//     event.preventDefault();

//     const joining = {
//       room: event.target.room.value,
//     };
//     onJoin(joining);
//   };

//   // get this from websocket
//   const { id } = useParams();

//   useEffect(() => {
//     if (id) {
//       const fetchData = async () => {
//         const response = await axios.get(
//           `https://2mcs9gz9e2.execute-api.us-east-1.amazonaws.com/prod/meeting/${id}`
//         );
//         onJoin({
//           room: response.data.meetingResponse.Meeting.ExternalMeetingId,
//         });
//       };
//       fetchData();
//     }
//   }, [id]);

//   return (
//     <Container component="main" maxWidth="xs">
//       <Typography component="p" variant="body1" marginTop="10px">
//         Start or join a conference room.
//       </Typography>
//       <Box component="form" onSubmit={handleSubmit}>
//         <TextField
//           name="room"
//           label="Conference room"
//           placeholder="Enter any alphanumeric id..."
//           maxLength="64"
//           minLength="2"
//           margin="normal"
//           inputProps={{
//             autoComplete: "off",
//           }}
//           fullWidth
//           required
//         />
//         <Button type="submit" variant="contained" fullWidth>
//           Start call
//         </Button>
//       </Box>
//     </Container>
//   );
// }

function Controls({ meetingSession, room, onLeave,type,toggleVideoOnOff, isVideoShow }) {
  var contentShareRef = useRef(null);
  const videoContentShare = useRef(null);

  const dispatch = useDispatch()
  const authToken = getLocalStorage('userToken')

  const [isMuted, setIsMuted] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [videoFilterOpen, setVideoFilterOpen] = useState(false)
  const [videoDevices, setVideoDevices] = useState([]);
 

  const { slider, fullScreen, startChat, allNotes } = useSelector((state) => state.app);

  const toggleMute = () => {
    if (isMuted) {
      const attendeeId = meetingSession?.configuration?.credentials?.attendeeId;
      meetingSession?.audioVideo?.realtimeUnmuteLocalAudio(attendeeId);
    } else {
      const attendeeId = meetingSession?.configuration?.credentials?.attendeeId;
      meetingSession?.audioVideo?.realtimeMuteLocalAudio(attendeeId);
    }
    setIsMuted(!isMuted);
  };

  // useEffect(() => {
  //   if (isVideoShow) {
  //     meetingSession.audioVideo.startLocalVideoTile();
  //   } else {
  //     meetingSession.audioVideo.stopLocalVideoTile();
  //   }
  // }, [isVideoShow]);

  const stopCall = async () => {
    try {
      const element = document.getElementById('note-section');
      const opt = {
        margin: 1,
        filename: 'notes.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      // Convert HTML to PDF Blob
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf('blob');

      // Convert PDF Blob to Base64
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result.split(',')[1];

        // Proceed with API call after base64String is set
        const payload = {
          notes: base64String
        };

      if(type === 'provider'){
            await dispatch(storeMeetingNotes({
          meeting_id: room, authToken, payload
        }));
      }

        dispatch(setStartVideoCall(false));
        dispatch(setFullScreen(false));
        dispatch(setSlider(true));
        dispatch(setIsMeetingEnded(true))
        meetingSession.audioVideo.stop();
        onLeave();
        dispatch(setAllNotes([]))
      };
      reader.readAsDataURL(pdfBlob);
    } catch (error) {
      console.error('Error in stopCall:', error);
    }
  };

  const toggleScreenSharing = async () => {

    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
    if (!meetingSession) return;

    try {
      if (!isSharing) {
        // Start screen sharing
        const observer = {
          videoTileDidUpdate: async (tileState) => {
            // Ignore a tile without attendee ID and videos.

            if (!tileState.boundAttendeeId || !tileState.isContent) {
              return;
            }
            if (tileState.isContent) {
             await meetingSession.audioVideo.bindVideoElement(
                tileState.tileId,
                videoContentShare.current
              );
            }
          },
          contentShareDidStart: (tileState) => {
            // Optional: handle content share start event if needed
            console.log("Screen share started");
          },
          contentShareDidStop: () => {
            console.log("Screen share stopped");
            setIsSharing(false); // Update the sharing state
          },
        };

        // Add observer for screen sharing
        meetingSession.audioVideo.addObserver(observer);

        // Start local video tile (optional, depends on your use case)
        
        // Start content share
        const contentShareStream = await meetingSession.audioVideo.startContentShareFromScreenCapture();
        if (contentShareRef.current) {
          DefaultVideoTile.connectVideoStreamToVideoElement(
            contentShareStream,
            contentShareRef?.current,
            false
          );
        }
        meetingSession.audioVideo.startVideoInput(contentShareStream);

        // Update sharing state
        setIsSharing(!isSharing);

      } else {
        // Stop screen sharing
        const observer = {
          contentShareDidStop: () => {
            console.log("Content share stopped");
            setIsSharing(false); // Update the sharing state
          },
        };

        // Add observer for stopping content share
        meetingSession.audioVideo.addContentShareObserver(observer);

        // Stop content share
        await meetingSession.audioVideo.stopContentShare();      
        // Start video input without any processing
        await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
      }
    } catch (error) {
      console.error("Error toggling screen sharing:", error);
    }
  };
  
  useEffect(() => {
    const screenData = async () => {
      try {
        if (contentShareRef.current != null && contentShareRef.current !== undefined) {
          const observer = {
            videoTileDidUpdate: async (tileState) => {
              if (!tileState.boundAttendeeId || !tileState.isContent) {
                return;
              }
              if (tileState.isContent) {
                meetingSession.audioVideo.bindVideoElement(
                  tileState.tileId,
                  videoContentShare.current
                );
              }
            },
            contentShareDidStart: (tileState) => {
              // Ignore a tile without attendee ID and videos.
              console.log("Screen share started");
            },
            contentShareDidStop: () => {
              console.log("Screen share stopped");
            },
          };
          meetingSession.audioVideo.addContentShareObserver(observer);
          meetingSession.audioVideo.addObserver(observer);
        } else {
          const observer = {
            contentShareDidStop: () => {
              console.log("Content share stopped");
            },
          };
          meetingSession.audioVideo.addContentShareObserver(observer);
          await meetingSession.audioVideo.stopContentShare();
        }
      } catch (error) {
        console.error("Error toggling screen sharing:", error);
      }
    };
    screenData();
  }, []);
  
  const handleChat = () => {
    dispatch(setStartChat(!startChat))
  }

  const handleSidebar = () => {
    dispatch(setSlider(!slider))
  }

  const handleFullScreen = () => {
    dispatch(setFullScreen(!fullScreen))
    if (type === "provider") {
      if (slider) {
        dispatch(setSlider(false))
      }
    }
  }


  useEffect(() => {
    const getVideoDevices = async () => {
      try {
        // Request permission to access the camera
        await navigator.mediaDevices.getUserMedia({ video: true });

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setVideoDevices(videoDevices);
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    getVideoDevices();
  }, []);

  let blurEnabled = false; // Track whether blur is enabled
  let isBackgroundActive = false; // Track background state
  let blurProcessor = null; // Store the blur processor instance
  let currentProcessor = null; // Store the current background processor

  //* add blur effect
  const toggleBackgroundBlur = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
  const videoInputDevices = devices.filter(device => device.kind === 'videoinput');

  if (blurEnabled) {
    // Disable blur effect
    blurProcessor = null; // Clear the reference to the processor
    await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
  } else {
    // Enable blur effect
    if (await BackgroundBlurVideoFrameProcessor.isSupported()) {
      const blurOptions = { intensity: 0.1 };
      blurProcessor = await BackgroundBlurVideoFrameProcessor.create(blurOptions);
      const processors = [blurProcessor];
      const transformDevice = new DefaultVideoTransformDevice(meetingSession.logger, videoInputDevices[0].deviceId, processors);
      
      const blurObserver = {
        filterFrameDurationHigh: (event) => {
          console.log(`Background filter duration high: frames dropped - ${event.framesDropped}, avg - ${event.avgFilterDurationMillis} ms`);
        },
        filterCPUUtilizationHigh: (event) => {
          console.log(`Background filter CPU high: CPU utilization is high ${event.cpuUtilization}`);
        },
      };
      
      processors.forEach(processor => processor.addObserver(blurObserver));
      await meetingSession.audioVideo.startVideoInput(transformDevice);
    }
  }

  blurEnabled = !blurEnabled;
  };
  
  //* Add BG image in video call
const handleBackgrounfImg = async (BGimage) => {
  const processors = [];
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoInputDevices = devices.filter(device => device.kind === 'videoinput');

  if (isBackgroundActive) {
    // Stop background removal and clear image
    if (currentProcessor && typeof currentProcessor.stop === 'function') {
      currentProcessor = null;
      await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
    }
    return; // Exit the function
  } else{
    if (await BackgroundReplacementVideoFrameProcessor.isSupported()) {
      try {
        const response = await fetch(BGimage);
        if (!response.ok) throw new Error('Failed to load image');
  
        const imageBlob = await response.blob();
        const options = {
          imageBlob,
          brightness: 1.2,
          contrast: 1.2
        };
        const replacementProcessor = await BackgroundReplacementVideoFrameProcessor.create(null, options);
        processors.push(replacementProcessor);
        currentProcessor = replacementProcessor; 
  
        const transformDevice = new DefaultVideoTransformDevice(
          meetingSession.logger,
          videoInputDevices[0].deviceId,
          processors
        );
  
        const BGObserver = {
          filterFrameDurationHigh: (event) => {
            console.log(`Background filter duration high: frames dropped - ${event.framesDropped}, avg - ${event.avgFilterDurationMillis} ms`);
          },
          filterCPUUtilizationHigh: (event) => {
            console.log(`Background filter CPU high: CPU utilization is high ${event.cpuUtilization}`);
          },
        };
  
        processors.forEach(processor => processor.addObserver(BGObserver));
        await meetingSession.audioVideo.startVideoInput(transformDevice);
      } catch (error) {
        console.error('Error loading background image:', error);
      }
    }
  }

  isBackgroundActive = true; // Set background state to active

  // Create and start background replacement processor

}

//* clear video filter
const clearAllFilters = async () => {
  // Reset blur state
  blurEnabled = false;
  blurProcessor = null;

  // Reset background state
  isBackgroundActive = false;
  currentProcessor = null;

  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoInputDevices = devices.filter(device => device.kind === 'videoinput');

  // Start video input without any processing
  await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
};

  return (
    <>
     <div className="h-100">
      <div className="d-none" id='note-section'>
        {allNotes.map((note, index) => (
          <p key={index}>{note}</p>
        ))}
      </div>
      {
        (type === "provider" || fullScreen) &&
        <div>
          <button className={`position-absolute z-3 ${fullScreen ? "sidebarButtonHide" : "miceOnOffButton"} ${window.innerWidth <= 767 &&"d-none"}`} onClick={handleSidebar} style={{ bottom: "75px", left: "2%" }}>
            <img src={images.sliderShowHideImg} alt="" style={{ transform: slider ? "" : 'rotate(180deg)' }} />
          </button>
        </div>
      }
      <div className="controlaContainer">
        <div className="centerIconBox backgroundBox">
          <button className="miceOnOffButton" onClick={toggleScreenSharing}>
            <img src={images.screenSharingImg} alt="" />
          </button>
          <button className="miceOnOffButton d-flex w-auto align-items-center position-relative" style={{ borderRadius: "50px", padding: "12px 6px 12px 12px", background:isVideoShow ? "#3B8FA9":""}} >
            <img src={images.videoOnImg} alt="" 
            onClick={toggleVideoOnOff}
             />
            <div className='p-1' onClick={() => setVideoFilterOpen(!videoFilterOpen)}>
              <FontAwesomeIcon icon={faAngleDown} color='#fff' style={{ rotate: videoFilterOpen ? "180deg" : "none" }} />
            </div>
            {
              videoFilterOpen &&
              <div className='video-filter d-flex flex-column'>
                <div>
                  <img src={images.videoCallImg} alt="" width={216} className="video-image"/>
                </div>

                <div>
                  <h1 className='text-start'>Camera</h1>
                  <div className='d-flex gap-2 align-items-center text-start mt-2'>
                    <input type="radio" checked/>
                    {videoDevices?.map((device) => (
                      <span key={device.deviceId}>{device.label || 'Unnamed Device'}</span>
                    ))}
                  </div>
                </div>
                <hr className='m-0' />
                <div className='filter-backgrouns text-start'>
                  <h1>Backgrounds</h1>
                  <div className='mt-2 d-flex gap-1'>
                    <img src={images.noFilter} alt="" onClick={clearAllFilters}/>
                    <img src={images.blurFilter} alt="" onClick={toggleBackgroundBlur}/>
                    <img src={images.filter2} alt="" onClick={()=>handleBackgrounfImg(images.filter2)} width={51}/>
                    <img src={images.filter1} alt="" onClick={()=>handleBackgrounfImg(images.filter1)} width={51}/>
                  </div>
                </div>

              </div>
            }
          </button>
          <button onClick={()=> toggleMute()} className="miceOnOffButton">
            {isMuted ? (
            <img src={MicOffIcon} alt="Mute audio" />
            ) : (
              <img src={images.micOnImg} alt="Mute audio" />
            )}
          </button>
          <button className="endCallButton" onClick={stopCall}>
            <img src={images.endCallImg} alt="" />
          </button>
          <button className="miceOnOffButton" onClick={handleChat}>
            <img src={images.videoChatImg} alt="" />
          </button>
          {/* <button className="miceOnOffButton">
            <img src={moreIcon} alt="" />
          </button> */}
        </div>

      </div>
      <div className={`centerIconBox ${(type === 'patient' && window.innerWidth <= 767 ) ? "" : type === 'patient' && 'bg-transparent'}`} style={{ position: "absolute", zIndex: 1, top: "25px", left: "2%" }}>
        {type === 'provider' && window.innerWidth > 767 &&
          <button className="miceOnOffButton">
            <img src={images.videoTileImg} alt="" />
          </button>
        }
        <button className="miceOnOffButton" onClick={handleFullScreen}>
          <img src={images.fullScreenImg} alt="" />
        </button>
      </div>
      <video
        ref={videoContentShare}
        // id="share-video"
        className="streaming-video streaming-video-remote"
      />
    </div>
    </>
  );
}

const PeerBox = ({ enabled, ...props }) => (
  <Box
    display={enabled ? "inline-block" : "none"}
    width="auto"
    height="61vh"
    backgroundColor="black"
    margin="10px"
    {...props}
  />
);

const Video = forwardRef((props, ref) => (
  <video
    ref={ref}
    width="100%"
    height="100%"
    style={{ objectFit: "cover" }}
    {...props}
  />
));

function AudioOutput({ meetingSession }) {
  // Create a reference to the audio element
  const audioRef = useRef(null);

  useEffect(() => {
    // Check if the audio element reference exists
    if (!audioRef.current) {
      console.error("No audio element found.");
      return;
    }

    // Get the current audio element
    const audioElement = audioRef.current;

    // Bind the audio element to the Chime SDK meeting session
    meetingSession.audioVideo.bindAudioElement(audioElement);

    // Define an observer for audio-video events
    const observer = {
      audioVideoDidStart: () => {
        console.log("Started");
      },
    };

    // Add the observer to the Chime SDK meeting session
    meetingSession.audioVideo.addObserver(observer);

    // Start the audio-video session
    meetingSession.audioVideo.start();
  }, [meetingSession]);

  // Render an invisible audio element
  return <InvisibleAudio ref={audioRef} />;
}

function StreamingVideosSection({ meetingSession, remoteUserData,localUserData, isVideoShow }) {
  const { selectedPrimaryColor } = useSelector((state) => state.onBoarding.selectedColors);
  const localVideoRef = useRef(null);

  const [localVideoExists, setLocalVideoExists] = useState(true)

  useEffect(() => {
    if (!localVideoRef.current) {
      console.error("No local video element found.");
      return;
    }

    const videoElement = localVideoRef?.current;

    const observer = {
      videoTileDidUpdate: (tileState) => {
        console.log("🚀 ~ useEffect ~ tileState:", tileState)
        if (!tileState.boundAttendeeId || !tileState.localTile) {
          return;
        }

        meetingSession.audioVideo.bindVideoElement(
          tileState?.tileId,
          videoElement
        );
        videoElement.id = `video-${tileState?.boundAttendeeId}`;
      },
    };
    
    meetingSession.audioVideo.addObserver(observer);
    meetingSession.audioVideo.startLocalVideoTile();
    meetingSession.audioVideo.start();
  }, [meetingSession]);  

  const videoSlotsRef = useRef(
    Array(25)
      .fill()
      .map(() => ({ tileId: null, video: null }))
  );

  const [enabledTiles, setEnabledTiles] = useState([]);
  const enableTile = (tileId) =>
    setEnabledTiles((previous) => [...previous, tileId]);
  const disableTile = (tileId) =>
    setEnabledTiles((previous) => previous.filter((p) => p !== tileId));
  const isEnabledTile = (tileId) => enabledTiles.includes(tileId);

  useEffect(() => {
    const findSlot = (tileId) =>
      videoSlotsRef.current.find((slot) => slot.tileId === tileId) ||
      videoSlotsRef.current.find((slot) => !slot.tileId);
    const mapToAssignedSlot = (assigningTileId, assigningSlot) =>
      videoSlotsRef.current.map((slot) =>
        slot.video === assigningSlot.video
          ? { ...slot, tileId: assigningTileId }
          : slot
      );
    const mapToUnassignedSlot = (unassigningTileId) =>
      videoSlotsRef.current.map((slot) =>
        slot.tileId === unassigningTileId ? { ...slot, tileId: null } : slot
      );

    const mutateVideoSlotsRef = (updatingSlots) => {
      videoSlotsRef.current = updatingSlots;
    };

    const observer = {
      videoTileDidUpdate: (tileState) => {
        
        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent
        ) {
          return;
        }

        let slot = findSlot(tileState?.tileId);
        if (!slot) {
          throw new Error("Failed to find slot for remote peer.");
        }

        mutateVideoSlotsRef(mapToAssignedSlot(tileState.tileId, slot));

        if (tileState.active) {
          enableTile(tileState?.tileId);
        }

        if(slot.video) {
          meetingSession.audioVideo.bindVideoElement(
            tileState.tileId,
            slot?.video
          );
          slot.video.id = `video-${tileState.boundAttendeeId}`;
        }
      },
      videoTileWasRemoved: (tileId) => {
        mutateVideoSlotsRef(mapToUnassignedSlot(tileId));
        disableTile(tileId);
      },
    };

    meetingSession?.audioVideo?.addObserver(observer);
  }, [meetingSession]);

  const exists = videoSlotsRef.current?.some(item => 
      item.tileId !== null && item?.video && item.video?.id.startsWith('video-')
  ) ?? false;

  useEffect(() => {
    if(isVideoShow){
      setLocalVideoExists(true)
    } else {
      setLocalVideoExists(localVideoRef?.current?.disablePictureInPicture)
    }
  }, [isVideoShow])

  return (
    <div className="h-100 position-relative">

      {/* {meetingSession && ( */}
        <div className="d-flex justify-content-center"
          style={{
            borderRadius: "16px",
            // position: videoSlotsRef?.current[0]?.tileId ? "absolute" : undefined,
            position: "absolute",
            right: "2%",
            top: "28px",
            // height: videoSlotsRef?.current[0]?.tileId ? "" : "100%",
          }}
        >
            <video
              ref={localVideoRef}
              className={`streaming-video streaming-video-local ${!localVideoExists && 'd-none'}`}
              style={{
                borderRadius: "16px",
                // position: videoSlotsRef?.current[0]?.tileId ? "absolute" : undefined,
                // width: videoSlotsRef?.current[0]?.tileId ? "250px" : "100%",
                width: "250px",
                // height: videoSlotsRef?.current[0]?.tileId ? "" : "100%",
                objectFit: videoSlotsRef?.current[0]?.tileId ? undefined : "cover"
              }}
            />

            { !localVideoExists &&
              <div className="local-video-off">
              <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
            {localUserData?.profile_image_path ? (
                      <div className="secure-profile">
                        <img
                          className="cursor-pointer blue_adminHomePamel"
                          src={process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + localUserData?.profile_image_path}
                          alt="provider-home-img"
                        />
                      </div>
                    ) : (
                      <div className="para-img-div-profile d-flex">
                        <div className="para-circle-avatar-profile m-0" style={selectedPrimaryColor ? { borderColor: selectedPrimaryColor, background: lightenColor(selectedPrimaryColor, 0.8) } : {}}>
                          <p className="para-image-profile">
                            {getInitials(localUserData.first_name + " " + localUserData.last_name)}
                          </p>
                        </div>
                      </div>
                    )}
            <h1>{localUserData.first_name + " " + localUserData.last_name}</h1>
          </div>
            </div>
            }
        </div>
        
      {videoSlotsRef?.current.map((slot, index) => {
        return(
        <div key={index} className={`${slot.tileId ? "d-flex justify-content-center w-100 h-100" : "d-none"}`}>
            <video
              key={index}
              ref={(video) => (slot.video = video)}
              className="streaming-video streaming-video-remote"
              style={{ width: "100%", display: slot.tileId ? "block" : "none", borderRadius: "20px" }}
            />
        </div>
      )}
      )}

      { 
      !exists && 
        <div className="video-off-ui w-100 h-100 d-flex justify-content-center align-items-center" >
          <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
            {remoteUserData?.profile_image_path ? (
                      <div className="secure-profile">
                        <img
                          className="cursor-pointer blue_adminHomePamel"
                          src={process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + remoteUserData?.profile_image_path}
                          alt="provider-home-img"
                        />
                      </div>
                    ) : (
                      <div className="para-img-div-profile d-flex">
                        <div className="para-circle-avatar-profile m-0" style={selectedPrimaryColor ? { borderColor: selectedPrimaryColor, background: lightenColor(selectedPrimaryColor, 0.8) } : {}}>
                          <p className="para-image-profile">
                            {getInitials(remoteUserData.first_name + " " + remoteUserData.last_name)}
                          </p>
                        </div>
                      </div>
                    )}
            <h1>{remoteUserData.first_name + " " + remoteUserData.last_name}</h1>
            <p className="camera-off">Turned camera off, but still able to hear you </p>
          </div>
        </div>
      }
    </div>
  );
}
export default Meeting;
