import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './ResetPasswordForm.scss';
import images from '../../../Assets/Images';
import { Auth } from 'aws-amplify';
import { EyeClose, EyeOpen } from '../../../Assets/Svgs';
import { passwordValidation } from '../../../Components/utils';
import { ToastMessage } from '../../../Components/Common/ToastMessage';

const ResetPasswordForm = () => {
    const address = window.location.search;
    const parameterList = new URLSearchParams(address);
    const email = parameterList.get("email") || '';
    const maskedEmail = email ? `${email.substring(0, 2)}${'*'.repeat(email.indexOf('@') - 2)}@${'*'.repeat(email.length - email.indexOf('@') - 6)}.com` : '';

    const initialValues = {
        code: '',
        newPassword: '',
        confirmPassword: '',
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const validationSchema = Yup.object().shape({
        code: Yup.string().required('Verification code is required'),
        newPassword: Yup.string()
            .required('New password is required')
            .test('is-valid-password', function (value) {
                const errorMessage = passwordValidation(value);
                if (errorMessage) {
                    return this.createError({ message: errorMessage });
                }
                return true;
            }),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const handleSubmit = async (values: any, { setErrors }: any) => {
        try {
            await Auth.forgotPasswordSubmit(email, values.code, values.newPassword);
            ToastMessage("success", 'Password successfully reset.');
        } catch (error) {
            ToastMessage("error", 'Failed to reset password.');
            setErrors({ form: 'Failed to reset password' });
        }
    };

    return (
        <div className='reset-password-form'>
            <img src={images.OnboardingBG} alt="" className='bg-image1' />
            <img src={images.OnboardingBG} alt="" className='bg-image2' />
            <div className='reset-form'>
                <div className='left-reset-password'>
                    <div className='left-head'>
                        <h1>Reset Password</h1>
                        <p>We have sent a password reset code by email to {maskedEmail}. Enter it below to reset your password.</p>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className='d-flex flex-column gap-3'>
                                    <div>
                                        <label className='form-label reset-label'>Enter Verification Code</label>
                                        <Field className='form-control reset-input' type='text' name='code' placeholder='Enter Code Here' />
                                        <ErrorMessage name='code' component='p' className='text-danger mt-1' />
                                    </div>
                                    <div className='position-relative'>
                                        <label className='form-label reset-label'>Password</label>
                                        <Field className='form-control reset-input' type={`${showPassword ? "text" : "password"}`} name='newPassword' placeholder='Enter New password' />
                                        <ErrorMessage name='newPassword' component='p' className='text-danger mt-1' />
                                        <img
                                            src={showPassword ? EyeOpen : EyeClose}
                                            className="position-absolute cursor-pointer"
                                            style={{ right: "15px", top: "44px" }}
                                            onClick={togglePasswordVisibility}
                                            alt=""
                                        />
                                    </div>
                                    <div className='position-relative'>
                                        <label className='form-label reset-label'>Confirm Password</label>
                                        <Field className='form-control reset-input' type={`${showConfirmPassword ? "text" : "password"}`} name='confirmPassword' placeholder='Confirm New Password' />
                                        <ErrorMessage name='confirmPassword' component='p' className='text-danger mt-1' />
                                        <img
                                            src={showConfirmPassword ? EyeOpen : EyeClose}
                                            className="position-absolute cursor-pointer"
                                            style={{ right: "15px", top: "44px" }}
                                            onClick={toggleConfirmPasswordVisibility}
                                            alt=""
                                        />
                                    </div>
                                    <ErrorMessage name='form' component='p' className='text-danger' />
                                    <button style={{ marginTop: "20px" }} className='yes-theme-btn theme-btn confirm-btn' type='submit' disabled={isSubmitting}>Confirm</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <hr className='m-0' />
                    <div className='reset-login'>
                        <p>Remember your password? <span>Log in</span></p>
                    </div>
                </div>
                <div className='right-reset-password'>
                    <div className='logo-border'>
                        <img src={images.Logo} alt="" />
                    </div>
                    <p>CENTIBLICK</p>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
