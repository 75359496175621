import React from 'react'

// css
import './OTPComponent.scss'
import { Field, Form, Formik } from 'formik';

const OTPComponent = ({ handleResendCode, registerEmail, handleVerification, setVerificationCode, formValue } : any) => {
    const initialValues = { otp: Array(6).fill('') };

    const handleChange = (values: any, setFieldValue: any, index: any, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Handle only digit input
        if (/^\d*$/.test(value)) {
            const newOtp = [...values.otp];
            newOtp[index] = value;

            // Automatically focus on the next input box
            if (value && index < 5) {
                setTimeout(() => document.getElementById(`otp-input-${index + 1}`)?.focus(), 0);
            }

            setFieldValue('otp', newOtp);
            setVerificationCode('otp', newOtp.join(''));
        }
    };

    const handleKeyDown = (values: any, setFieldValue: any, index: any, event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key;

        if (key === 'Backspace') {
            const newOtp = [...values.otp];

            // If the current input is empty, move focus to the previous input
            if (!newOtp[index] && index > 0) {
                newOtp[index - 1] = ''; // Clear the previous input
                setFieldValue('otp', newOtp);
                setTimeout(() => document.getElementById(`otp-input-${index - 1}`)?.focus(), 0);
            } else if (newOtp[index]) {
                newOtp[index] = ''; // Clear the current input
                setFieldValue('otp', newOtp);
            }
        }
        if (key === 'Enter') {
            const otp = values.otp.join('');
            handleVerification(formValue)
        }
    };

    const handleClear = (setFieldValue : any) => {
        setFieldValue('otp', Array(6).fill(''));
        document.getElementById('otp-input-0')?.focus(); // Focus on the first input after clearing
    };
    

    return (
        <Formik initialValues={initialValues} onSubmit={(values) => console.log(values)}>
            {({ values, setFieldValue }) => (
                <Form>
                    <div className="otp-container d-flex justify-content-between">
                        {values.otp.map((digit, index) => (
                            <Field
                                key={index}
                                id={`otp-input-${index}`}
                                name={`otp[${index}]`}
                                type="text"
                                className="otp-input form-control py-3 px-2 text-center"
                                maxLength={1}
                                value={digit}
                                onChange={(e : any) => handleChange(values, setFieldValue, index, e)}
                                onKeyDown={(e : any) => handleKeyDown(values, setFieldValue, index, e)}
                            />
                        ))}
                    </div>
                    <div className="resend-btns">
                        <button
                            type="button"
                            className="save-theme-btn"
                            style={{ padding: "12px 16px" }}
                            onClick={() => handleClear(setFieldValue)}
                        >
                            Clear
                        </button>
                        <button
                            type="button"
                            className="save-theme-btn"
                            style={{ padding: "12px 16px" }}
                            onClick={() => handleResendCode(registerEmail)}
                        >
                            Resend OTP
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default OTPComponent