import React, { useState, useEffect } from "react";

// redux
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Store";

// assets
import DefaultSquare from '../../../../../Assets/Svgs/ImageUploadSquare.svg'
import images from "../../../../../Assets/Images";

interface SidebarComponentProps {
  ComponentTitle?: "Standard Platform" | "Customize"
}

const SidebarButtons = ({ ComponentTitle }: SidebarComponentProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors);
  const selectedType = useSelector((state: RootState) => state.onBoarding.selectedType);

  const { firstImage: ProviderFirstImage }: any = useSelector((state: RootState) => state.onBoarding.ProviderselectedImage);

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    const pathname = location.pathname;
    setCurrentPath(pathname);
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [location, navigate]);

  function handleButtonName() {
    switch (ComponentTitle) {
      case "Standard Platform":
        return "Consolidate my Medical Records"
      case 'Customize':
        return "Consolidate my Medical Records";
      default:
        return "Consolidate my Medical Records"
    }
  }

  function handleWaitingRoomIcon() {
    switch (ComponentTitle) {
      case "Standard Platform":
        return images.shadedLogo
      case 'Customize':
        return {
          isSVG: ProviderFirstImage && ProviderFirstImage.includes('<svg'),
          content: ProviderFirstImage ? ProviderFirstImage : DefaultSquare
        };
      default:
        return images.shadedLogo
    }
  }

  const PatientSidebarCloud = `<svg width="30" height="30" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2644_33586)">
  <rect x="13" y="13" width="64" height="64" rx="32" fill="white"/>
  <rect x="13" y="13" width="64" height="64" rx="32" fill="url(#paint0_radial_2644_33586)" fill-opacity="0.7"/>
  <rect x="13" y="13" width="64" height="64" rx="32" fill="url(#paint1_radial_2644_33586)"/>
  <rect x="11.4" y="11.4" width="67.2" height="67.2" rx="33.6" stroke="white" stroke-width="3.2"/>
  <mask id="mask0_2644_33586" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="23" y="23" width="44" height="44">
  <rect x="23.2031" y="23.2031" width="43.5928" height="43.5928" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_2644_33586)">
  <path d="M43.1871 59.5228H35.0134C32.2586 59.5228 29.9049 58.5692 27.9523 56.662C25.9997 54.7548 25.0234 52.4238 25.0234 49.669C25.0234 47.3077 25.7348 45.2038 27.1577 43.3571C28.5805 41.5105 30.4423 40.3299 32.743 39.8152C33.4998 37.0301 35.0134 34.7748 37.2839 33.0493C39.5544 31.3237 42.1275 30.4609 45.0034 30.4609C48.5454 30.4609 51.5499 31.6946 54.0172 34.1618C56.4844 36.629 57.718 39.6336 57.718 43.1755C59.8068 43.4177 61.5399 44.3183 62.9173 45.8773C64.2948 47.4364 64.9835 49.2603 64.9835 51.3491C64.9835 53.6196 64.1888 55.5495 62.5995 57.1388C61.0102 58.7281 59.0803 59.5228 56.8098 59.5228H46.8198V46.5358L49.726 49.3511L52.2689 46.8082L45.0034 39.5428L37.738 46.8082L40.2809 49.3511L43.1871 46.5358V59.5228Z" fill="${ComponentTitle === "Standard Platform" ? "#3B8FA9" : selectedPrimaryColor}"/>
  </g>
  </g>
  <defs>
  <filter id="filter0_d_2644_33586" x="0.200781" y="0.196875" width="89.5984" height="89.6063" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="4.8"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2644_33586"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2644_33586" result="shape"/>
  </filter>
  <radialGradient id="paint0_radial_2644_33586" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.8889 26.3333) rotate(51.0441) scale(53.7245 53.7245)">
  <stop stop-color="white" stop-opacity="0.59"/>
  <stop offset="0.6875" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2644_33586" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.2329 69) rotate(-93.6705) scale(54.779 61.0535)">
  <stop stop-color="#3B8FA9" stop-opacity="0.34"/>
  <stop offset="0.0001" stop-color="${ComponentTitle === "Standard Platform" ? "#3B8FA9" : selectedPrimaryColor}" stop-opacity="0.4"/>
  <stop offset="0.861815" stop-color="${ComponentTitle === "Standard Platform" ? "#3B8FA9" : selectedPrimaryColor}" stop-opacity="0"/>
  </radialGradient>
  </defs>
  </svg>
  `
  const PatientInfo = `<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.0078 27.5C8.10425 27.5 2.50781 21.9035 2.50781 15C2.50781 8.09644 8.10425 2.5 15.0078 2.5C21.9113 2.5 27.5078 8.09644 27.5078 15C27.5078 21.9035 21.9113 27.5 15.0078 27.5ZM15.0078 25C20.5307 25 25.0078 20.5229 25.0078 15C25.0078 9.47715 20.5307 5 15.0078 5C9.48496 5 5.00781 9.47715 5.00781 15C5.00781 20.5229 9.48496 25 15.0078 25ZM16.2578 13.125V18.75H17.5078V21.25H12.5078V18.75H13.7578V15.625H12.5078V13.125H16.2578ZM16.8828 10C16.8828 11.0355 16.0433 11.875 15.0078 11.875C13.9723 11.875 13.1328 11.0355 13.1328 10C13.1328 8.96446 13.9723 8.125 15.0078 8.125C16.0433 8.125 16.8828 8.96446 16.8828 10Z" fill="${ComponentTitle === "Standard Platform" ? "#3B8FA9" : selectedPrimaryColor}"/>
  </svg>
  `

  return (
    <>
      {selectedType == "Patient" &&
        <div className={`sidebar-buttons-box`}>
          <button
            className={
              currentPath === "/connect-my-medical-records" ||
                currentPath.startsWith("/my-virtual-care/home") ||
                currentPath.startsWith("/my-medication") ||
                currentPath.startsWith("/my-connected-devices") ||
                currentPath.startsWith("/EHR-portals") ||
                currentPath.startsWith("/well-being")
                ? "btn sidebtn active"
                : "btn sidebtn"
            }
            style={{ backgroundColor: ComponentTitle === "Standard Platform" ? "" : selectedSecondaryColor }}
            data-bs-dismiss={deviceWidth > 767 ? "" : "offcanvas"}
          >
            <div className="d-flex align-items-center justify-content-between w-100 text-center">
              <div className="sidebar-icon-box" dangerouslySetInnerHTML={{ __html: PatientSidebarCloud }} />
              <span className="commonFont-10">{handleButtonName()}</span>
              <div className="d-flex">
                <div className="sidebar-icon-box" dangerouslySetInnerHTML={{ __html: PatientInfo }} />
              </div>
            </div>
          </button>
        </div>
      }
      {
        selectedType == "Provider" &&
        <div className={`sidebar-buttons-box m-0`}>
          <button
            className="btn w-100"
            style={{ background: ComponentTitle === "Standard Platform" ? "#3B8FA91A" : `${selectedSecondaryColor ? selectedSecondaryColor : "81, 108, 211"}`, border: ComponentTitle === "Standard Platform" ? "1px solid #3B8FA9" : `1px solid ${selectedPrimaryColor}`, padding: "0px" }}
          >
            <div className="waiting-room gap-2 d-flex provider-screen">
              <div className="Image-Outer-Bg p-1">
                {handleWaitingRoomIcon() && typeof handleWaitingRoomIcon().content === 'string' ? (
                  handleWaitingRoomIcon().isSVG ? (
                    <img src={`data:image/svg+xml;utf8,${encodeURIComponent(handleWaitingRoomIcon().content)}`} alt="waiting room icon" />
                  ) : (
                    <img src={handleWaitingRoomIcon().content} alt="waiting room icon" />
                  )
                ) : (
                  <img src={ComponentTitle === "Standard Platform"? handleWaitingRoomIcon() : `data:image/svg+xml;utf8,${encodeURIComponent(handleWaitingRoomIcon().content)}`} alt="waiting room icon" />
                )}
              </div>
              <h6>Virtual Waiting Room </h6>
            </div>
          </button>
        </div>
      }
    </>
  );
};

export default SidebarButtons;
