import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Draft } from 'immer';

interface meetingMessages {
    text: string;
    fromUser: boolean;
    timestamp: string;
}

interface AppState {
    waitingTime: { [userId: string]: any };
    chatMessages: {
        [userId: string]: meetingMessages[];
      };
    unreadMessages: { [userId: string]: number };
    webSocket: WebSocket | null;
}

const initialState: AppState = {
    waitingTime: {},
    chatMessages: {},
    unreadMessages: {},
    webSocket: null
};

export const webSocketSlice = createSlice({
    name: 'webSocket',
    initialState,
    reducers: {
        setWebSocket: (
            state: Draft<typeof initialState>,
            action: PayloadAction<any>
        ) => {
            state.webSocket = action.payload;
        },
        setWaitingTime: (
            state: Draft<AppState>,
            action: PayloadAction<{ userId: string; waitingTime: number }>
        ) => {
            const { userId, waitingTime } = action.payload;
            state.waitingTime[userId] = waitingTime; // Set waiting time for the specific user
        },
        setChatMessages: (
            state: Draft<typeof initialState>,
            action: PayloadAction<{ [userId: string]: meetingMessages }>
        ) => {
            const messagesByUser = action.payload;

            // Use Object.entries to handle the messages without an explicit loop
            Object.entries(messagesByUser).forEach(([userId, message]) => {
                // Initialize the array for this user if it doesn't exist
                state.chatMessages[userId] = state.chatMessages[userId] || [];

                // Push the new message to the existing array
                state.chatMessages[userId].push(message);
                if (!message.fromUser) {
                    state.unreadMessages[userId] = (state.unreadMessages[userId] || 0) + 1;
                }
            });
        },
        resetUnreadCount: (
            state: Draft<AppState>,
            action: PayloadAction<string> // Accept userId to reset the count
        ) => {
            const userId = action.payload;
            state.unreadMessages[userId] = 0; // Reset unread count for the specific user
        },
    },
});

export const { setWaitingTime, resetUnreadCount, setChatMessages, setWebSocket } = webSocketSlice.actions;
