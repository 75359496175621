import React, { useState } from 'react'

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../Store';

//css
import UpcomingAppointments from './UpcomingAppointments';

import '../../Header/CommonHeader.scss';
import DefaultImage from '../../../../../../Assets/Svgs/ImageUploadSquare.svg'
import images from '../../../../../../Assets/Images';
import { lightenColor } from '../../../../../../Components/utils';
import { ChatOption, FileCopy } from '../../../../../../Assets/Svgs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';

interface SidebarComponentProps {
  ComponentTitle?: "Standard Platform" | "Customize"
}



const HomeDeviceListing = ({ ComponentTitle }: SidebarComponentProps) => {



  const { selectedType } = useSelector((state: RootState) => state.onBoarding);
  const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors);

  const [isHovered, setIsHovered] = useState(false);

  // patient images
  const { firstImage, secondImage }: any = useSelector((state: RootState) => state.onBoarding.selectedImage);

  // provder images
  const { thirdImage: ProviderThirdImage, secondImage: ProviderSecondImage }: any = useSelector((state: RootState) => state.onBoarding.ProviderselectedImage);

  // admin images
  const { firstImage: AdminFirstImage, secondImage: AdminSecondImage, thirdImage: AdminThirdImage, forthImage: AdminForthImage }: any = useSelector((state: RootState) => state.onBoarding.AdminselectedImage);

  function CarouselImage({ ComponentTitle, itemType }: any) {
    switch (ComponentTitle) {
      case "Customize":
        // Handle Customize case based on itemType
        switch (itemType) {
          case "Patient":
            return [firstImage ? firstImage : DefaultImage, secondImage ? secondImage : DefaultImage];
          case "Provider":
            return [ProviderSecondImage ? ProviderSecondImage : DefaultImage, ProviderThirdImage ? ProviderThirdImage : DefaultImage, DefaultImage];
          case "Admin":
            return [images.carouselProviderProfile, images.carouselPatientProfile, images.Encounters, images.Analytics];
          default:
            return [DefaultImage, DefaultImage, DefaultImage, DefaultImage];
        }

      case "Standard Platform":
        // Handle Standard Platform case based on itemType
        switch (itemType) {
          case "Patient":
            return [images.CHCareLogo, images.CHForms];
          case "Provider":
            return [images.providerTasksSquare, images.carouselPatientAppointments];
          case "Admin":
            return [images.carouselProviderProfile, images.carouselPatientProfile, images.Encounters, images.Analytics];
          default:
            return [DefaultImage];
        }

      default:
        return [DefaultImage];
    }
  }



  let PatientItems = [
    {
      name: "my Virtual Care",
      image: CarouselImage({ ComponentTitle, itemType: "Patient" })
    },
    {
      name: "my Forms",
      image: CarouselImage({ ComponentTitle, itemType: "Patient" })
    },
  ];


  const providerItems = [
    {
      name: "Patient Profiles",
      image: CarouselImage({ ComponentTitle, itemType: "Provider" })

    },
    {
      name: "Encounters",
      image: CarouselImage({ ComponentTitle, itemType: "Provider" })

    },
    {
      name: "Analytics",
      image: CarouselImage({ ComponentTitle, itemType: "Provider" })

    },
  ]
  const AdminItems = [
    {
      name: "Provider Profiles",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
    {
      name: "Patient Profiles",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
    {
      name: "Encounters",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
    {
      name: "Analytics",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
  ]

  const filteredAdminCarouselDetails = (() => {
    switch (selectedType) {
      case 'Patient':
        return PatientItems;
      case "Provider":
        return providerItems;
      case 'Admin':
        return AdminItems;
      default:
        return PatientItems
    }
  })();


  const Ring1 = `<svg width="131" height="113" viewBox="0 0 131 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M75.7553 98.3561C109.963 98.3561 137.415 70.3443 137.415 36.1361C137.415 1.92798 109.963 -26.0838 75.7553 -26.0838C41.5477 -26.0838 14.0959 1.92798 14.0959 36.1361C14.0959 70.3443 41.5477 98.3561 75.7553 98.3561Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="28.027"/>
    </g>
    </svg>
    `

  const Ring2 = `<svg width="131" height="80" viewBox="0 0 131 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M75.7553 138.661C109.963 138.661 137.415 110.649 137.415 76.4408C137.415 42.2327 109.963 14.2209 75.7553 14.2209C41.5477 14.2209 14.0959 42.2327 14.0959 76.4408C14.0959 110.649 41.5477 138.661 75.7553 138.661Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="28.027"/>
    </g>
    </svg>
    `

  const Ring3 = `<svg width="205" height="207" viewBox="0 0 205 207" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M102.678 197.931C154.584 197.931 196.568 155.592 196.568 103.48C196.568 51.3684 154.584 9.0294 102.678 9.0294C50.7717 9.0294 8.78721 51.3684 8.78721 103.48C8.78721 155.592 50.7717 197.931 102.678 197.931Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="16.8162"/>
    </g>
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M102.67 162.051C134.756 162.051 160.686 135.907 160.686 103.755C160.686 71.6039 134.756 45.4591 102.67 45.4591C70.5844 45.4591 44.6544 71.6039 44.6544 103.755C44.6544 135.907 70.5844 162.051 102.67 162.051Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="16.8162"/>
    </g>
    </svg>
    `

  const waitingRoomData = [
    {
      name: 'Kali Li',
      dob: 'Feb 29, 1988',
      waitTime: '15 min',
      image: images.sidebarKali
    },
    {
      name: 'James Allen',
      dob: 'Feb 29, 1988',
      waitTime: '25 min',
      image: images.sidebarJames
    },
    {
      name: 'Nova Williams',
      dob: 'Feb 29, 1988',
      waitTime: '35 min',
      image: images.timeHeaderImg
    }
  ];

  const hoverStyle = {
    background: isHovered ? `radial-gradient(
      107.08% 85.59% at 86.3% 87.5%,
      rgba(0, 0, 0, 0.23) 0%,
      rgba(0, 0, 0, 0) 86.18%
    ),
    radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${selectedPrimaryColor}`
      : 'white',
    border: `1px solid ${selectedPrimaryColor}`,
    cursor: 'pointer',
    color: isHovered ? 'white' : '',
    borderRadius: "8px"
  };

  return (
    <div className={`care-history-mobile hometasklist mt-3 mx-3 col-md-8 screen-appointment ${selectedType === 'Provider' && 'ms-0'}`}
      style={{ width: selectedType === 'Patient' ? "64%" : "" }}
    >
      <div dangerouslySetInnerHTML={{ __html: Ring1 }} className='ring-1' />
      <div dangerouslySetInnerHTML={{ __html: Ring2 }} className='ring-2' />
      <div dangerouslySetInnerHTML={{ __html: Ring3 }} className='ring-3' />
      {
        selectedType === 'Patient' &&
        <div className={`row hybridPatient m-auto`}>
          <h1 className='clinic-meeting-header'>
            Your call with Dr. De Marco will start soon
          </h1>
          <div className='clinic-meeting d-flex' style={{ borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor, background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.8) }}>
            <div className='d-flex flex-column align-items-center clinic-details w-100' style={{ borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor, }}>
              <img src={images.ClinicProvider} alt="" width={59} height={59} />
              <div>
                <h1 >
                  Dr. De Marco
                </h1>
                <p>
                  MD, PhD
                </p>
              </div>
              <div className='provider-staus'>
                Offline
              </div>
              <p>Waiting for your provider to come online</p>
            </div>
            <div className='clinic-chat w-100' style={{ borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor, }}>
              <h1 className='p-1' style={{ background: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor, }}>
                Send Dr. De Marco a message
              </h1>
              <div className='clinic-chat-div'>

              </div>
              <div className='clinic-send-chat'>
                <input
                  type="text"
                  className='w-75'
                  placeholder='Type your message...'
                  style={{ borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor, }}
                />
                <button
                  className="btn w-25 account-btn theme-btn shadow-none"
                  style={{
                    color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                  107.08% 85.59% at 86.3% 87.5%,
                  rgba(0, 0, 0, 0.23) 0%,
                  rgba(0, 0, 0, 0) 86.18%
                ),
                radial-gradient(
                  83.94% 83.94% at 26.39% 20.83%,
                  rgba(255, 255, 255, 0.41) 0%,
                  rgba(255, 255, 255, 0) 69.79%,
                  rgba(255, 255, 255, 0) 100%
                ),
                ${selectedPrimaryColor}`
                  }}
                >
                  send
                  {/* {selectedType === "Provider" ? "Update Profile" : "Update my Account"} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      {(selectedType === "Provider") &&
        <div className={`row hybridPatient m-auto`}>
          <div className={`patient-carousel z-1 col-xl-6`}>
            <div className='outer-section provider-center provider-outer-section w-100'>
              <div className={`appointment-card p-2 d-flex flex-column align-items-start gap-1 shadow-none border-0`}
                style={{ background: selectedSecondaryColor }}
              >
                <div className="appointment-detail">
                  <div>
                    <h3 className="text-start commonFont-10" >
                      Welcome Dr. Kelly Warren
                    </h3>
                    <p className='commonFont-10'>To invite someone to your waiting room share this link.</p>

                  </div>
                </div>
                <div className="invite-link w-100 d-flex align-item-center justify-content-between p-1">
                  <input
                    // ref={inputRef}
                    type="text"
                    className="text-truncate"
                    placeholder="https://teams.microsoft.com/l/mee..."
                    value={`https://centiblickconnect.com/provider/Kelly/aec47eef-93ed-40e8-b702-1bb14485a9de`}
                    readOnly
                  />
                  <button className="yes-theme-btn z-1 cursor-pointer" style={{ background: selectedPrimaryColor }}>
                    <img src={FileCopy} alt="" />
                  </button>
                </div>

                <button className="onboarding-save-theme-btn w-100 invite-patient-btn"
                  style={hoverStyle}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  Invite Patient
                </button>
              </div>

            </div>
          </div>

          <div className={`col-lg-12 col-xl-6 `}>
            <div className='w-100 d-flex flex-column justify-content-between h-100 mb-4'>
              <div>
                <div className="waiting-room commonFont-10 p-2" style={{ marginBottom: "10px", border: `1px solid ${selectedPrimaryColor}`, background: lightenColor(selectedSecondaryColor, 0.8) }}>
                  <h2 className={` text-center w-100`}>
                    Virtual Waiting Room
                  </h2>
                </div>
                <div>
                  {waitingRoomData.map((person, index) => (
                    <div
                      key={index}
                      className="waiting-room-box"
                      style={{ border: `1px solid ${selectedPrimaryColor}`, background: selectedSecondaryColor }}
                    >
                      <div className="d-flex justify-content-between gap-1">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={person.image}
                            className="img-fluid"
                            alt="Image1"
                            height={31}
                            width={31}
                          />
                          <div>
                            <p className="secure-user-name commonFont-10 clinic-name">{person.name}</p>
                            <p className="wait-time-span commonFont-10 clinic-dob">DOB: {person.dob}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <FontAwesomeIcon icon={faMessage} color="#3B8FA9" size="sm" />
                          <p className="ml-2 mb-0 secure-user-time commonFont-10">
                            Wait time: <span>{person.waitTime}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className={`d-flex align-items-end position-relative `}>
                <div className="d-flex justify-content-end align-items-end position-relative w-100">
                  <img src={ChatOption} alt="" width={50} height={50} className="cursor-pointer" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      }
      {selectedType === "Admin" &&
        <div className={`row hybridPatient ${selectedType === "Admin" && "hybridAdmin"}`}>
          <div className={`patient-carousel z-1 row flex-row m-auto`}>
            {filteredAdminCarouselDetails.map((item: any, index: any) => {
              return (
                <div className={`new-carousel col-xl-6 px-2`} key={`carousel_item_${index}`}>
                  <div
                    className="ch-card-item admin-home-ch mb-2"
                    key={`carousel_item_${index}`}
                  >
                    <div className="ch-card ch-card provider-ch-card admin-ch-card pe-none" style={{ backgroundColor: ComponentTitle === "Standard Platform" ? "" : selectedSecondaryColor }}>
                      {/* {
                        ComponentTitle === "Customize" &&
                        <div className='p-2' style={{ background: "#fff", borderRadius: "4px", border: item.image[index] === DefaultImage ? `1px solid ${selectedPrimaryColor}` : `1px solid ${selectedPrimaryColor}` }}>
                          {item.image ? (
                            item.image[index]?.includes('<svg') ? (
                              <div dangerouslySetInnerHTML={{ __html: item.image[index] }} />
                            ) : (
                              <>
                                <img src={item.image[index]} alt="first image" />
                              </>
                            )
                          ) : (
                            <img src={DefaultImage} alt="Default Image" />
                          )}
                        </div>
                      }
                      {
                        ComponentTitle === "Standard Platform" &&
                      } */}
                      <div style={{ borderRadius: "4px", border: item.image ? "none" : `1px solid ${selectedPrimaryColor}` }}>
                        <img src={item.image && item.image[index]} alt="first image" />
                      </div>
                      <h3 className='commonFont-10'>{item.name}</h3>
                    </div>
                  </div>

                </div>
              );
            })}
          </div>

          <div className={`col-lg-12 col-xl-6 ${selectedType == "Admin" && 'd-none'}`}>
            <UpcomingAppointments ComponentTitle={ComponentTitle} />
          </div>
        </div>
      }
    </div>
  )
}

export default HomeDeviceListing;