const SocketEvent = (socket : any) => {
    
    function sendHeartbeat() {
        if (socket.readyState === WebSocket.OPEN) { // Check if the WebSocket connection is open
            socket.send(JSON.stringify({ action: "heartbeat" }));
            console.log("Heartbeat sent");
        } else {
            console.log("WebSocket is not open. Can't send heartbeat.");
        }
    }

    // Start sending heartbeat every 5 minutes (300000 milliseconds)
    setInterval(sendHeartbeat, 300000);
};

export default SocketEvent;
