import React, { useState } from 'react'
// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store';

// components
import Navbar from './Navbar';
import HomeDeviceListing from './HomeDeviceListing/HomeDeviceListing';

// utils
import { lightenColor } from '../../../../../Components/utils';

// assets
import images from '../../../../../Assets/Images';
import SidebarButtons from './SidebarButtons';

interface SidebarComponentProps {
  ComponentTitle?: "Standard Platform" | "Customize",
  type?:string;
}
const Sidebar = ({ ComponentTitle,type }: SidebarComponentProps) => {
  const { selectedPrimaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)
  const selectedType = useSelector((state: RootState) => state.onBoarding.selectedType);

  const [isChecked, setIsChecked] = useState(false);


  if (ComponentTitle === "Customize") {
    const styleElement = document.createElement('style');

    const cssRule = `#inner-screen, #inner-screen * { color: ${selectedPrimaryColor}; ::-webkit-scrollbar-thumb { background: ${selectedPrimaryColor}; } }`;

    styleElement.appendChild(document.createTextNode(cssRule));
    document.head.appendChild(styleElement);
  }

  function handleProfileName() {
    switch (ComponentTitle) {
      case "Standard Platform":
        return handleName();

      case 'Customize':
        return "John Doe";

      default:
        return handleName();
    }
  }
  function handleProfileButtonName() {
    switch (ComponentTitle) {
      case "Standard Platform":
        return "my Account";

      case 'Customize':
        return "Update my Account";

      default:
        return selectedType === "Provider" ? "Update Profile" : "Update my Account"
    }
  }

  const handleName = () => {
    switch (selectedType) {
      case "Patient":
        return "Jane Smith";
      case "Provider":
        return "Dr. Kelly Warren";
      case "Admin":
        return "Pamela McCoy"
      default:
        return 'Jane Doe'
    }
  }

  const handleDegree = () => {
    switch (selectedType) {
      case "Patient":
        return "";
      case "Provider":
        return "Primary Care, MD, PhD";
      case "Admin":
        return "Virtual Care Administration"
      default:
        return ''
    }
  }

  const handleImageChange = () => {
    switch (ComponentTitle) {
      case "Standard Platform":
        return handleImage();
      case 'Customize':
        return images.LaptopProfile;
      default:
        return images.LaptopProfile;
    }
  }

  const handleImage = () => {
    switch (selectedType) {
      case "Patient":
        return images.sidebarJane;
      case "Provider":
        return images.timeHeaderImg;
      case "Admin":
        return images.adminHomePamel;
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className='d-flex position-relative z-3' id={ComponentTitle === "Customize" ? "inner-screen" : undefined}>
      <div
        className="side-screen-wraper d-md-block col-md-2 z-1 "
        style={{ display: type ==="toggle-sidebar"? "" :"none", background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.8), }}

      >
        <div className={`sidebar screen-sidebar ${selectedType === "Admin" && "admin-navbar"} ${selectedType === "Provider" && "provider-navbar"}`}>
          <div
            className="sidebar-user patient-sidebar-user d-md-flex"
            style={{ display: "none" }}
          >
            <img
              className="cursor-pointer"
              src={handleImageChange()}
              alt="user"
            />
            <div className='text-center'>
              <h2 className="cursor-pointer commonFont-10">
                {handleProfileName()}
              </h2>
              <p className={`education ${selectedType == "Patient" && 'd-none'}`}>{handleDegree()}</p>
            </div>
            {(selectedType === "Patient" || selectedType === "Provider") && (
              <button
                className="btn account-btn theme-btn shadow-none "
                style={{
                  color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                  107.08% 85.59% at 86.3% 87.5%,
                  rgba(0, 0, 0, 0.23) 0%,
                  rgba(0, 0, 0, 0) 86.18%
                ),
                radial-gradient(
                  83.94% 83.94% at 26.39% 20.83%,
                  rgba(255, 255, 255, 0.41) 0%,
                  rgba(255, 255, 255, 0) 69.79%,
                  rgba(255, 255, 255, 0) 100%
                ),
                ${selectedPrimaryColor}`
                }}
              >
                {handleProfileButtonName()}
                {/* {selectedType === "Provider" ? "Update Profile" : "Update my Account"} */}
              </button>
            )}

            {selectedType == "Admin" &&
              <div className='row gap-2 justify-content-center w-100'>
                <button
                  className="col-md-5 p-2 btn account-btn theme-btn shadow-none "
                  style={{
                    color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                    107.08% 85.59% at 86.3% 87.5%,
                    rgba(0, 0, 0, 0.23) 0%,
                    rgba(0, 0, 0, 0) 86.18%
                  ),
                  radial-gradient(
                    83.94% 83.94% at 26.39% 20.83%,
                    rgba(255, 255, 255, 0.41) 0%,
                    rgba(255, 255, 255, 0) 69.79%,
                    rgba(255, 255, 255, 0) 100%
                  ),
                  ${selectedPrimaryColor}`
                  }} >
                  Update Profile
                </button>
                <button
                  className="col-md-5 p-2 btn account-btn theme-btn shadow-none" style={{
                    color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                    107.08% 85.59% at 86.3% 87.5%,
                    rgba(0, 0, 0, 0.23) 0%,
                    rgba(0, 0, 0, 0) 86.18%
                  ),
                  radial-gradient(
                    83.94% 83.94% at 26.39% 20.83%,
                    rgba(255, 255, 255, 0.41) 0%,
                    rgba(255, 255, 255, 0) 69.79%,
                    rgba(255, 255, 255, 0) 100%
                  ),
                  ${selectedPrimaryColor}`
                  }} >
                  Schedule
                </button>
              </div>
            }
            {selectedType == "Provider" &&
              <div className="provider-service-content mt-2">
                <div className="switch-btn d-flex gap-3 align-items-center ">
                  <div className="start-btn">
                    <button className="btn green-btn" style={{ color: "#fff" }}>On Service</button>
                  </div>
                  <label className="switch">
                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                    <span className="slider round" style={{ border: ComponentTitle === "Standard Platform" ? "1px solid #3B8FA9" : `1px solid ${selectedPrimaryColor}`, background: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor }}></span>
                  </label>
                </div>
              </div>
            }
          </div>
          <SidebarButtons ComponentTitle={ComponentTitle} />

          {selectedType == "Patient" && <><Navbar ComponentTitle={ComponentTitle} /></>}

          {selectedType == "Admin" &&
            <div className="admin-laptop-screen" style={{ border: ComponentTitle === "Standard Platform" ? "1px solid #3B8FA9" : `1px solid ${selectedPrimaryColor}` }}>
              <div className="mb-3">
                <h3>Appointment Cancellations</h3>
                <div className="task-list-box mt-2">
                  <div className="tasklist-item" style={{ background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.85), border: ComponentTitle === "Standard Platform" ? "" : `1px solid ${selectedPrimaryColor}` }}>
                    <img src={images.sidebarJane} alt="providerWelcome" className=" blue_providerWelcome" />
                    <div>
                      <p className='commonFont-8'>Maya Mehta</p>
                      <p className='commonFont-8' style={{ fontSize: "7px", fontWeight: "400" }}>Received Nov 3, 2023</p>
                    </div>
                    <div className="">
                      <button className="btn pro-light-btn " style={{ border: ComponentTitle === "Standard Platform" ? "" : `1px solid ${selectedPrimaryColor}` }}>
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
     {type === "toggle-sidebar" ? null : <HomeDeviceListing ComponentTitle={ComponentTitle} />}
    </div>
  )
}

export default Sidebar