import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useRef, useState } from "react";
import images from "../../../Assets/Images";
import { FileUploader } from "react-drag-drop-files";
import {
    capitalize,
    capitalizeEachWord,
    formatPhoneNumber,
    GenderOption,
    savePDFAsFormData,
    USAStatesCB,
} from "../../../Components/utils";
import CBSelect from "../../../Components/Common/CBSelect/CBSelect";
import CBSelectState from "../../../Components/Common/CBSelect/CBSelectState";
import SignaturePad from "react-signature-canvas";
import * as Yup from "yup";
import {
    DefaultImageGallery,
    ImageUploadSquare,
    CloseModelIcon,
    ShareIcon,
} from "../../../Assets/Svgs";
import UploadCropImageModal from "../Home/Modals/UploadCropImageModal";
import Cropper from "cropperjs";

//css
import "./WaitingScreen.scss";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import { storePatientProfileData } from "../../../Store/Thunk/storeProfileData/storePatientProfileThunk";
import { storeImages } from "../../../Store/Thunk/storeProfileData/storeImagesThunk";
import { getLocalStorage } from "../../../Components/Patient/Utils/AuthHandlers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store";
import { SavePatientInformation } from "../../../Store/Thunk/myForms/PatientInformation/SavePatientInformationThunk";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
interface FileState {
    file: File | null;
    fileName: string | null | undefined;
    fileBuffer: string | null;
}


const PatientFormFlow = ({ handleSaveClick }: any) => {
    const patientUserData = useSelector((state: RootState) => state.patientProfile.PatientUserData);

    const UserData = getLocalStorage('userData');
    const Email = getLocalStorage('userEmail');
    const authToken = getLocalStorage('userToken');
    // const room_id = getLocalStorage("room_id")
    const room_id = sessionStorage.getItem("room_id")
    const dispatch = useDispatch()

    const userId = UserData.sub

    const [profilePicture, setProfilePicture] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const [driversLicense, setDriversLicense] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const [insuranceCard, setInsuranceCard] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const [tempFile, setTempFile] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });
    const [tempFileIC, setTempFileIC] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const resultRef = useRef<HTMLDivElement>(null);
    const saveRef = useRef<HTMLButtonElement>(null);
    const croppedRef = useRef<HTMLImageElement>(null);
    const uploadRef = useRef<HTMLInputElement>(null);
    const sigTeleHealthCanvas: any = useRef(null);

    const imgResultRef = useRef<HTMLDivElement>(null);
    const [cropper, setCropper] = useState<Cropper | null>(null);
    const [isUplodOpen, setIsUploadOpen] = useState(false);
    const [isNotSelectedDL, setIsNotSelectedDL] = useState(false);
    const [isNotSelectedIC, setIsNotSelectedIC] = useState(false);
    const [selectedImage, setSelectedImage] = useState<any>(ImageUploadSquare);
    const [modalTitle, setModaltitle] = useState<string>("Driver's License");
    const [isSaveClick, setIsSaveClick] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState(null);

    const clear = () => {
        setImageURL(null);
        if (sigTeleHealthCanvas?.current?.clear) {
            sigTeleHealthCanvas?.current?.clear();
        }
    };

    const handleChange = (
        file: File,
        setFileState: React.Dispatch<React.SetStateAction<FileState>>
    ) => {
        setFileState((prevFileState) => ({
            ...prevFileState,
            file: file,
            fileName: file.name,
        }));

        let reader = new FileReader();
        reader.onloadend = function () {
            setFileState((prevFileState) => ({
                ...prevFileState,
                fileBuffer: reader.result as string,
            }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        toast.loading("Please wait...");

        let dataUrl: any;
        if (sigTeleHealthCanvas.current) {
            const canvas = sigTeleHealthCanvas.current.getTrimmedCanvas();
            dataUrl = canvas.toDataURL("image/png");
            setImageURL(dataUrl);
        }

        const ImagePayload: any = {
            user_id: userId,
            type: 'patient',
            profilePicture: {
                imageName: profilePicture.fileName,
                buffer: profilePicture.fileBuffer,
            },
            driversLicense: {
                imageName: driversLicense.fileName,
                buffer: driversLicense.fileBuffer,
            },
            insuranceCard: {
                imageName: insuranceCard.fileName,
                buffer: insuranceCard.fileBuffer,
            }
        };

        const ProfilePayload = {
            firstName: values.firstName || null,
            lastName: values.lastName || null,
            middleName: values.middleName || null,
            address: values.currentAddress || null,
            city: values.city || null,
            state: values.state || null,
            zipCode: values.zipCode || null,
            dob: values.birthDate || null,
            gender: values.gender || null,
            phone: values.phoneNumber || null,
            email: values.email || null,
            groupNumber: values.groupNumber || null,
            policyNumber: values.policyNumber || null,
            insurance: values.insuranceCompanyName || null,
            emergencyContactName: values.emergencyContactName || null,
            emergencyContactNumber: values.emergencyPhoneNumber || null,
            profileImagePath: profilePicture.fileName || null,
            licenseImagePath: driversLicense?.fileName || null,
            insuranceImagePath: insuranceCard?.fileName || null,
            street: null,
            provider_id: room_id
        };

        try {
            let result: any;
            if (profilePicture.fileBuffer || driversLicense.fileBuffer || insuranceCard.fileBuffer) {
                result = await dispatch(storeImages({ authToken, ImagePayload, userId, profilepayload: ProfilePayload, type: 'patient' }));
            } else {
                result = await dispatch(storePatientProfileData({ authToken, data: ProfilePayload, userId }));
            }

            if (result.payload && result.payload.status === 200) {
                ToastMessage("success", 'Data saved successfully!')
                // const pdfbase64 = await savePDFAsFormData('medical-form-box', 'Telehealth Consent', capitalizeEachWord(values.clientName));

                // const payload: any = {
                //     client_name: values.clientName || '',
                //     dob: values.birthDate || '',
                //     witness: values.witness || '',
                //     used_marijuana_recreationally: values.marijuanaRecreationally || '',
                //     relationship_to_client: values.relationToClient || '',
                //     telehealth_consent_s3_path: dataUrl ? dataUrl : '',
                //     telehealth_consent_form_base_64_string: pdfbase64 || '',
                //     telehealth_consent_signature_s3_path: dataUrl ? dataUrl : '',
                //     offered_copy: values.offeredCopy || '',
                //     authorized_first_name: values?.authorized_first_name || '',
                //     authorized_last_name: values?.authorized_last_name || '',
                //     legalRelationship: values?.legalRelationship || '',
                //     signDate: values?.signDate || '',
                // };

                // const response: any = await dispatch(SavePatientInformation({ type: "TelhealthConscent", patient_id: userId, payload, authToken }));

                // const ResponseMessage = JSON.parse(response.payload)
                // if (ResponseMessage.message === "success") {
                //     toast.dismiss();
                //     ToastMessage("success", "Profile saved successfully");
                //     handleSaveClick(values);
                // } else {
                //     throw new Error('Error in saving patient information');
                // }
            } else {
                throw new Error('Error in saving profile data or images');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.dismiss();
            ToastMessage("error", "Error in saving Data");
        } finally {
            setSubmitting(false);
        }
    };


    const initialValues: any = {
        currentAddress: "",
        city: "",
        state: "",
        zipCode: "",
        email: Email,
        phoneNumber: "",
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
    };

    const validationSchema = Yup.object().shape({
        currentAddress: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().test("State", "Invalid State", (value) => {
            if (!value) return true; // Allow empty values
            const validStates = USAStatesCB.map((state) => state.value);
            const validStatesLAbel = USAStatesCB.map((state) =>
                state.label?.toUpperCase()
            );
            if (value?.length === 2) {
                return validStates.includes(value);
            } else if (value?.length >= 2) {
                if (validStatesLAbel.includes(value?.toUpperCase())) {
                    return validStatesLAbel.includes(value?.toUpperCase());
                } else {
                    return false;
                }
            }
        }).required('State is required'),
        zipCode: Yup.string().matches(
            /^\d{5}(-\d{4})?$/,
            "ZIP Code must be in the format XXXXX OR XXXXX-XXXX"
        ).required('ZIP Code is required'),
        email: Yup.string().email("Invalid email address"),
        phoneNumber: Yup.string()
            .matches(
                /^\(\d{3}\) \d{3}-\d{4}$/,
                "Phone number must be in the format (XXX) XXX-XXXX"
            ),

        firstName: Yup.string().required("First Name is required"),
        middleName: Yup.string(),
        lastName: Yup.string().required("Last Name is required"),
        gender: Yup.string(),
        birthDate: Yup.date().max(moment().format("MM/DD/YYYY")).required('Birthdate is required'),
    });

    const handleModalOpen = (index: number, image1: any) => {
        let image = image1;
        setIsUploadOpen(true);
        switch (index) {
            case 0:
                setTempFile((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: null,
                    fileBuffer: image,
                    // fileBuffer: null
                }));
                if (driversLicense?.fileName) {
                    setSelectedImage(image);
                    image = image1;
                    setIsNotSelectedDL(false);
                } else {
                    setIsNotSelectedDL(true);
                    image = ImageUploadSquare;
                    setSelectedImage(DefaultImageGallery);
                }
                setModaltitle("Driver's License");
                break;
            case 1:
                setTempFileIC((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: null,
                    fileBuffer: image,
                    // fileBuffer: null
                }));
                if (insuranceCard?.fileName) {
                    setSelectedImage(image);
                    image = image1;
                    setIsNotSelectedIC(false);
                } else {
                    image = ImageUploadSquare;
                    setSelectedImage(DefaultImageGallery);
                    setIsNotSelectedIC(true);
                }
                // if(insuranceCard?.fileName) {
                //     setSelectedImage(image);
                //     image=image1
                // }else{
                //     image=ImageUploadSquare
                //     setSelectedImage(ImageUploadSquare);
                // }
                setModaltitle("Insurance Card");
                break;
            default:
                setModaltitle("");
                break;
        }

        if (image && typeof image === "string") {
            const isPDF = image.endsWith(".pdf");
            const isExternalLink = image.startsWith("http");
            const img = new Image();
            img.src = image;
            const isInternalLink = img.src.startsWith("data:application/pdf");

            if (isPDF || isExternalLink || isInternalLink || selectedImage) {
                imgResultRef.current?.classList.add("hide");
                saveRef.current?.classList.add("hide");
            } else {
                imgResultRef.current?.classList.remove("hide");
                saveRef.current?.classList.remove("hide");
            }

            const resultElement = resultRef.current;

            if (resultElement) {
                resultElement.innerHTML = "";

                if (isPDF) {
                    const link = `https://docs.google.com/viewer?embedded=true&url=${image}`;
                    resultElement.innerHTML = `<iframe name="myFrame" id="myFrame" src="${link}" width="100%" height="200px"></iframe>`;
                } else {
                    if (isInternalLink) {
                        resultRef.current.innerHTML = `<iframe name="myFrame" id="myFrame" src="${img.src}" width="100%" height="200px"></iframe>`;
                    } else {
                        img.onload = () => {
                            resultElement.appendChild(img);
                        };

                        img.onerror = (error) => {
                            console.error("Error loading image:", error);
                        };
                    }
                }
            } else {
                console.error("resultRef.current is null");
            }

            croppedRef.current?.setAttribute("src", image);
        } else {
            if (resultRef.current) {
                resultRef.current.innerHTML = "";
            }
            setCropper(null);
        }
    };

    const HandleModalclose = () => {
        setIsUploadOpen(false);
        if (uploadRef?.current) {
            uploadRef.current.value = "";
        }
        if (modalTitle == "Driver's License") {
            if (driversLicense?.fileName) {
                setDriversLicense((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: "",
                    fileBuffer: null,
                    // fileBuffer: null
                }));
            } else if (selectedImage) {
                if (isNotSelectedDL) {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        //  fileBuffer: null
                    }));
                } else {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        //  file: null,
                        //  fileName:null,
                        fileBuffer: tempFile?.fileBuffer,
                        // fileBuffer: null
                    }));
                }
            } else if (selectedImage) {
                if (isNotSelectedDL) {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        // fileBuffer: tempFile?.fileBuffer,
                    }));
                } else {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: "",
                        fileBuffer: null,
                    }));
                }
            }
        } else {
            // setInsuranceCard((prevFileState) => ({
            //     // ...prevFileState,
            //     file: null,
            //     fileName: selectedImage?null:insuranceCard?.fileName ? insuranceCard?.fileName : null,
            //     fileBuffer: null,

            // }))

            if (insuranceCard?.fileName) {
                setInsuranceCard((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: "patientUserData?.insurance_image_path",
                    fileBuffer: null,
                    // fileBuffer: null
                }));
            } else if (insuranceCard?.fileBuffer == selectedImage) {
                if (isNotSelectedIC) {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        //  fileBuffer: null
                    }));
                } else {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        //  file: null,
                        //  fileName:null,
                        fileBuffer: tempFileIC?.fileBuffer,
                        // fileBuffer: null
                    }));
                }
            } else if (selectedImage) {
                if (isNotSelectedIC) {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        // fileBuffer: tempFile?.fileBuffer,
                    }));
                } else {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                    }));
                }
            }
        }
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0];

            let reader = new FileReader();

            reader.onload = (e) => {
                const fileContent = e.target?.result as string;
                if (e.target?.result) {
                    if (file.type === "application/pdf") {
                        if (e.target && e.target instanceof HTMLInputElement) {
                            e.target.value = "";
                        }
                        if (resultRef.current) {
                            resultRef.current.innerHTML = `<iframe src="${fileContent}" width="100%" height="200px"></iframe>`;
                        }
                        if (saveRef.current) {
                            imgResultRef.current?.classList.add("hide");
                            saveRef.current.classList.add("hide");
                        }
                        if (modalTitle == "Driver's License") {
                            setDriversLicense({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        } else {
                            setInsuranceCard({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        }
                        setSelectedImage(fileContent);
                    } else {
                        imgResultRef.current?.classList.remove("hide");
                        let img = document.createElement("img");
                        img.id = "image";
                        img.src = fileContent;
                        setSelectedImage(fileContent);
                        croppedRef.current?.setAttribute("src", fileContent);
                        if (modalTitle == "Driver's License") {
                            setDriversLicense({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        } else {
                            setInsuranceCard({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        }
                        if (resultRef.current) {
                            resultRef.current.innerHTML = "";
                            resultRef.current.appendChild(img);
                        }

                        if (saveRef.current) {
                            saveRef.current.classList.remove("hide");
                        }
                        setCropper(new Cropper(img, { autoCropArea: 30 }));
                    }
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePreview = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (cropper && croppedRef.current) {
            if (
                profilePicture.file &&
                profilePicture.file.type === "application/pdf"
            ) {
                // Skip preview for PDF files
                setIsSaveClick(true);
            } else {
                // Handle preview for image files
                let imgSrc = cropper
                    .getCroppedCanvas({
                        width: 300,
                    })
                    .toDataURL();

                if (imgResultRef.current) {
                    imgResultRef.current.classList.remove("hide");
                }
                croppedRef.current.src = imgSrc;
                setIsSaveClick(true);
            }
            setSelectedImage(croppedRef.current.src);
        }
        setTimeout(() => {
            setIsSaveClick((prevIsSaveClick) => !prevIsSaveClick);
        }, 500);
    };

    const cropFun = (e?: any) => {
        e?.preventDefault();
        if (cropper && croppedRef.current) {
            if (
                profilePicture.file &&
                profilePicture.file.type === "application/pdf"
            ) {
                // Skip preview for PDF files
                setIsSaveClick(true);
            } else {
                // Handle preview for image files
                let imgSrc = cropper
                    ?.getCroppedCanvas({
                        width: 300,
                    })
                    ?.toDataURL();

                if (imgResultRef.current) {
                    imgResultRef.current.classList.remove("hide");
                }
                croppedRef.current.src = imgSrc;
                setIsSaveClick(true);
            }
            return croppedRef.current.src;
        }
        setTimeout(() => {
            setIsSaveClick((prevIsSaveClick) => !prevIsSaveClick);
        }, 500);
    };

    const hadleSave = async () => {
        const data: any = await cropFun();
        if (croppedRef.current && croppedRef.current.src) {
            if (modalTitle == "Driver's License") {
                setDriversLicense((prevFileState) => ({
                    ...prevFileState,
                    fileBuffer: data
                }))

            } else {
                setInsuranceCard((prevFileState) => ({
                    ...prevFileState,
                    fileBuffer: data

                }))
            }

        }
        const ImagePayload: any = {
            user_id: UserData.sub,
            type: 'patient',
            driversLicense: {
                imageName: driversLicense.fileName,
                buffer: driversLicense.fileBuffer,
            },
            insuranceCard: {
                imageName: insuranceCard.fileName,
                buffer: insuranceCard.fileBuffer,
            }
        };

        const ProfilePayload = {
            firstName: initialValues.firstName || null,
            lastName: initialValues.lastName || null,
            middleName: initialValues.middleName || null,
            address: initialValues.currentAddress || null,
            city: initialValues.city || null,
            state: initialValues.state || null,
            zipCode: initialValues.zipCode || null,
            dob: initialValues.birthDate || null,
            gender: initialValues.gender || null,
            phone: initialValues.phone || null,
            email: initialValues.email || null,
            groupNumber: initialValues.groupNumber || null,
            policyNumber: initialValues.policyNumber || null,
            insurance: initialValues.insuranceCompanyName || null,
            emergencyContactName: initialValues.emergencyContactName || null,
            emergencyContactNumber: initialValues.emergencyPhoneNumber || null,
            // profileImagePath: profilePicture.fileName || null,
            licenseImagePath: driversLicense.fileName || null,
            insuranceImagePath: insuranceCard.fileName || null,
            street: null
        }
        setLoader(true)
        toast.loading("Please wait...")
        const response: any = await dispatch(storeImages({ authToken, ImagePayload: ImagePayload, userId, profilepayload: ProfilePayload, type: 'patient' }));
        if (response?.payload.status === 200) {
            ToastMessage("success", "Save Image successfully")
            setIsUploadOpen(false);
        }
        if (uploadRef?.current) {
            uploadRef.current.value = ''
        }
        setLoader(false)

    }

    const selectImage = () => {
        if (uploadRef?.current) {
            uploadRef.current.value = "";
            uploadRef.current?.click();
        }
    };

    const handleRemoveImage = (title: string) => {
        if (title === "Insurance Card") {
            setInsuranceCard({
                file: null,
                fileName: null,
                fileBuffer: null,
            });
        } else {
            setDriversLicense({
                file: null,
                fileName: null,
                fileBuffer: null,
            });
        }
    };

    const fileTypes = ["JPEG", "PNG", "JPG"];

    const TelehealthItems = [
        "I acknowledge that the confidentiality protections provided by state and federal laws extend to telehealth sessions.",
        "I acknowledge that telehealth involves transmitting my personal health information electronically and may be shared with colleagues and practitioners as part of my care and services.",
        "I understand that I can choose to withhold or withdraw my consent for the use of telehealth in my care at any time, without affecting my future care or treatment.",
        "I understand that my provider may need to end my telehealth session.",
        "I acknowledge my right to review all information gathered and recorded during a telehealth interaction, and I may request copies of this information for a reasonable fee.",
        "I understand that there are various alternative healthcare methods available to me and I may seek out such methods at my discretion.",
        "I acknowledge my responsibility to inform my provider of any electronic interactions regarding my care with other healthcare providers.",
        "I understand that while telehealth may provide anticipated benefits in my care, no specific outcomes can be guaranteed.",
        "Besides my healthcare provider, I will be informed of any other individuals present during the appointment, and I reserve the right to request their exclusion from the viewing and listening area.",
        "I understand that audio, video, or photo recordings containing personal and medical information may be securely transmitted and may become part of my permanent medical record.",
        "I will ensure that only necessary individuals are present in my viewing and listening area during the virtual appointment and I will find a quiet space so that I can enable the communication between me and the provider.",
        "In case of a medical emergency, I will contact local authorities (9-1-1) for assistance.",
        "I have the right to withhold specific details of my medical history or physical examination if I choose.",
        "My healthcare provider may advise immediate treatment or determine a medical emergency, in which case, personal details may be provided to local authorities for assistance without further consent.",
        "I have the right to ask direct questions about this appointment, including inquiries about the privacy policy.",
        "I understand that this communication is privileged, and I will not record it without permission from my healthcare provider.",
        "I acknowledge that I may be responsible for co-payments, deductibles, or other charges related to telehealth services.",
        "I understand that I may download/print a copy of this signed Telehealth Consent for my records.",
    ];

    const introduction = [
        "Patient health records",
        "Live two-way audio and video",
        "Output data from health devices and sound and video files",
    ];

    const expectedBenefits = [
        "Improved access to care by enabling a patient to remain in his/her provider's office (or at a remote site) while the provider obtains test results and consults from practitioners at distant/other sites.",
        "More efficient patient evaluation and management.",
        "Obtaining expertise of a distant specialist.",
    ];

    const possibleRisks = [
        "In rare cases, information transmitted may not be sufficient (e.g., poor resolution of images) to allow for appropriate decision-making by the providers and consultant(s).",
        "Delays in evaluation and treatment could occur due to deficiencies or failures of the equipment.",
        "In very rare instances, security protocols could fail, causing a breach of privacy of personal health information.",
        "In rare cases, a lack of access to complete health records may result in interactions or allergic reactions or other judgment errors.",
    ];

    return (
        <>
            <div className="fill-form">
            <h1 className="waiting-header mb-4">Let’s complete your profile</h1>
                <div className="col-lg-12 col-xl-12">
                    <div className="share_medical_record_card account-information-form">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            {(formik: any) => (
                                <Form>
                                    <h3
                                        className="sm_card_title d-flex justify-content-between pb-3"
                                        style={{ borderBottom: "1px solid #dadada" }}
                                    >
                                        my Account Information
                                        <div className="d-flex gap-2">
                                            {/* <div
                                                className="yes-theme-btn header-buttons d-flex justify-content-center align-items-center"
                                                style={{
                                                    width: "41px",
                                                    height: "41px",
                                                    borderRadius: "50%",
                                                    padding: 0,
                                                }}
                                            >
                                                <img src={ShareIcon} alt="" height={20} />
                                            </div> */}
                                            <button
                                                className="yes-theme-btn"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </h3>

                                    <div
                                        className="medical-form-box pt-4 p-2"
                                        style={{
                                            maxHeight: "calc(100vh - 460px)",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                First Name{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.firstName &&
                                                                    formik.errors.firstName
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="firstName"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="firstName" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="form-group">
                                                            <label>Middle Name</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.middleName &&
                                                                    formik.errors.middleName
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="middleName"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="middleName" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Last Name <span className="text-danger">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.lastName &&
                                                                    formik.errors.lastName
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="lastName"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="lastName" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>Address <span className="text-danger">*</span></label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.currentAddress &&
                                                            formik.errors.currentAddress
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="currentAddress"
                                                    />
                                                    <p>
                                                        <ErrorMessage name="currentAddress" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>City <span className="text-danger">*</span></label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.city && formik.errors.city
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="city"
                                                    />
                                                    <p>
                                                        <ErrorMessage name="city" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>State <span className="text-danger">*</span></label>
                                                    {/* <Field
                            type="text"
                            className={`form-control ${formik.touched.state && formik.errors.state ? 'error-border' : ''}`}
                            name="state"

                          /> */}
                                                    <CBSelectState
                                                        options={USAStatesCB}
                                                        seletedItem={formik.values.state}
                                                        onChange={(option: string) => {
                                                            if (option?.length === 2) {
                                                                formik.setFieldValue("state", option);
                                                            } else {
                                                                const validStatesLAbel = USAStatesCB.filter(
                                                                    (state) =>
                                                                        state.label?.toUpperCase() ===
                                                                        option?.toUpperCase()
                                                                );
                                                                if (validStatesLAbel?.length > 0) {
                                                                    formik.setFieldValue(
                                                                        "state",
                                                                        validStatesLAbel[0]?.value
                                                                    );
                                                                } else {
                                                                    formik.setFieldValue("state", option);
                                                                }
                                                            }
                                                        }}
                                                        className={`${formik.touched.state && formik.errors.state
                                                            ? "error-border"
                                                            : ""
                                                            } w-100 m-0 StateDropdown-class`}
                                                        placeholder="Select"
                                                    />

                                                    <p>
                                                        <ErrorMessage name="state" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>Zip <span className="text-danger">*</span></label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.zipCode && formik.errors.zipCode
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="zipCode"
                                                    />
                                                    {/* <p><ErrorMessage name="zipCode" /></p> */}
                                                    <p className="formik-validation-red-message">
                                                        {formik.validateOnBlur && formik.errors.zipCode
                                                            ? formik.errors.zipCode
                                                            : null}{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Date of Birth <span className="text-danger">*</span></label>
                                                    <Field
                                                        type="date"
                                                        max="9999-12-31"
                                                        className={`form-control`}
                                                        name="birthDate"
                                                    />
                                                    <p>
                                                        <ErrorMessage name="birthDate" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>
                                                        Phone Number
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.validateOnBlur && formik.errors.phoneNumber
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="phoneNumber"
                                                        placeholder="(000) 000-0000"
                                                        onBlur={formik.handleBlur}
                                                        onChange={(e: any) => {
                                                            const formattedValue = formatPhoneNumber(
                                                                e.target.value
                                                            );
                                                            formik.setFieldValue(
                                                                "phoneNumber",
                                                                formattedValue
                                                            );
                                                        }}
                                                    />
                                                    <p className="formik-validation-red-message">
                                                        {formik.validateOnBlur && formik.errors.phoneNumber
                                                            ? formik.errors.phoneNumber
                                                            : null}{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.email && formik.errors.email
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="email"
                                                        placeholder='Enter email id'
                                                    />
                                                    <p>
                                                        <ErrorMessage name="email" />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                {
                    <UploadCropImageModal
                        showModal={isUplodOpen}
                        modalTitle={modalTitle}
                        closeModal={HandleModalclose}
                        selectedImage={selectedImage}
                        confirm={() => console.log("hhelo")}
                        handleChangeImage={handleChangeImage}
                        uploadRef={uploadRef}
                        resultRef={resultRef}
                        imgResultRef={imgResultRef}
                        croppedRef={croppedRef}
                        saveRef={saveRef}
                        handlePreview={handlePreview}
                        cropper={cropper}
                        hadleSave={hadleSave}
                        selectImage={selectImage}
                        loader={loader}
                        patientUserData={patientUserData}
                    />
                }
            </div>
        </>
    );
};

export default PatientFormFlow;
