import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { post } from "../../../Network/ApiService";
import axios from "axios";

interface storeClinicStaffDataArgs {
    payload: any;
}

export const storeClinicStaffData = createAsyncThunk<any, storeClinicStaffDataArgs, { state: RootState }>(
    "storeClinicStaffData",
    async ({payload} , { dispatch }) => {
        try {
            const ApiEndPoint= `${ApiUrl.patient_lookup_creation}`
            console.log("payload", payload);
            
            const response = await axios.post(ApiEndPoint, payload);
            if (response) {
                return response;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error;
        }
    }
)