// aws imports
import { Auth } from "aws-amplify";
import { store } from "../../../Store";
import AdminProfileSlice from "../../../Store/Slicers/ProfileInformation/adminProfileSlice";
import ProviderProfileSlice from "../../../Store/Slicers/ProfileInformation/providerProfileSlice";
import PatientProfileSlice from "../../../Store/Slicers/ProfileInformation/patientProfileSlice";
import { reset } from "../../../Store/Slicers/commonSlicer";

export async function logoutHandler(adminPlan?:any) {
  try {
    await Auth.signOut();
    localStorage.clear()
    store.dispatch(AdminProfileSlice.actions.resetState());
    store.dispatch(ProviderProfileSlice.actions.resetState());
    store.dispatch(PatientProfileSlice.actions.resetState());
    reset()
    window.location.assign("/admin/login");
  } catch (error) {
    console.error("Sign-out error:", error);
  }
}

export const setLocalStorage = (itemName: string, itemValue: any) => {
  localStorage.setItem(itemName, itemValue);
};

export const deleteLocalStorage = (itemName: string) => {
  localStorage.removeItem(itemName);
};

export const getLocalStorage = (itemName: string) => {
  try {
    return JSON.parse(localStorage.getItem(itemName) || "{}");
  } catch (error) {
    return localStorage.getItem(itemName);
  }
};
