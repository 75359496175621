import React from "react";

//css
import "./Investors.scss";

//assets
import images from "../../Assets/Images";

//hook
import { useInvestors } from "./useInvestors";
import Footer from "../Footer/Footer";

import ListIcon from "../../Assets/Svgs/ListIcon.svg";
import SignInHeader from "../../Components/Common/SignInHeader/SignInHeader";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ChartOutLook } from "../../Assets/Svgs";
import Chart from "../../Components/Common/Chart";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Investors = () => {
  const { navigate } = useInvestors();

  const options: any = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        align: "start",
        position: "chartArea",
        labels: {
          boxWidth: 12,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: false,
          text: "Months",
        },
        ticks: {
          font: {
            size: 12,
            weight: 700,
          },
        },
      },
      y: {
        position: "right",
        grid: {
          display: false,
        },
        title: {
          display: false,
          text: "Values",
        },
        ticks: {
          callback: (value: any) => {
            let valueInMillions = value / 1000;

            const increment = 4;

            valueInMillions = Math.round(valueInMillions / increment) * increment;

            const formattedValue = valueInMillions.toFixed(2).replace(/\.?0+$/, '') + "M";
            return "$" + formattedValue;
          },
          position: "left",
          font: {
            size: 12,
            weight: 700,
          },
        },
      },
    },
  };

  const data: any = {
    labels: ["2024", "2025", "2026", "2027", "2028"],
    datasets: [
      {
        label: "Revenue",
        data: [1600, 7800, 9400, 11200, 20000],
        backgroundColor: "#3B8FA9",
        barThickness: 30,
        datalabels: {
          anchor: "end",
          align: "top",
          display: true,
          // padding: {
          //   left: 10,
          //   right: 10
          // },
          // backgroundColor: "#DADADA",
          color: "#000",
          borderRadius: 20,
          borderWidth: 4,
          font: {
            size: 11,
            family: 'Roboto'
          }
        },
      },
      {
        label: "Subscriptions",
        data: [1500, 7800, 9360, 11232, 20009],
        backgroundColor: "#DADADA",
        barThickness: 3,
      },
    ],
  };
  return (
    <div>
      <div className="pricing-page investor">
        <div className="first">
          <img src={images.PriceBackground} alt="PriceBackground" />
        </div>
        <SignInHeader />
        <div className="our">
          <h1 className="pricing-header">Business Overview</h1>
        </div>
        <div className="second">
          <img src={images.PriceBackground} alt="PriceBackground" />
        </div>
      </div>
      <div className="d-flex flex-column bottom-content ">
        <div className="row">
          <div className="col-md-6 upper-box mx-auto">
            <div className="d-flex box-1 flex-column">
              <div className="main d-flex flex-column justify-content-between">
                <h1 className="d-flex justify-content-center box-heading">
                  Telehealth Software Market
                </h1>
                <img
                  src={ChartOutLook}
                  alt="InvestorsOutlook"
                  className="whole-image"
                />
                {/* <div className="circle-section chart mx-auto">
                  <div className="inside-circle">
                    <div className="d-flex">
                      <div className="head">
                        <h3>
                          6.95B
                        </h3>
                        <p>Total available Market</p>
                        <img
                          src={images.InvestorsOutlook}
                          alt="InvestorsOutlook"
                        />
                      </div>
                      <div className="blue-circle">
                        <div className="blue-section">
                          <div className="blue-details d-flex flex-column justify-content-center">
                            <img
                              src={LandingLogo}
                              alt="logo"
                              onClick={() => navigate("/")}
                              className="logo"
                            />
                            <h3>$1.5B</h3>
                            <p>Centiblick Target Market</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="d-flex link justify-content-between">
                  <p>https://www.futuremarketinsights.com/reports/telehealth-software-market1.7M</p>
                  <p>CB TAM 1.7M x $900/year</p>
                </div>
              </div>
            </div>
            <div className="d-flex box flex-column">
              <div className="main d-flex flex-column">
                <h1 className="box-heading">
                  Market Challenger Revenue Opportunities
                </h1>
                <img src={`${process.env.REACT_APP_AWS_S3_BUCKET_PATH}/TargetMarket.svg`} alt="Revenue" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 bottom-box mx-auto">
            <div className="d-flex box flex-column">
              <div className="main d-flex flex-column" style={{ gap: "70px" }}>
                <h1 className="d-flex justify-content-center box-heading">
                  Key Market Drivers
                </h1>
                <ul className="text-start px-4 telehealth-market">
                  <li className="d-flex"><span > <img className="list-icons" src={ListIcon} alt="check" /></span> <p>Data Security & Compliance</p></li>
                  <li className="d-flex"><span > <img className="list-icons" src={ListIcon} alt="check" /></span> <p>Integrates Seamlessly with Existing Technology</p></li>
                  <li className="d-flex"><span > <img className="list-icons" src={ListIcon} alt="check" /></span> <p>Supports Existing Workflow</p></li>
                  <li className="d-flex"><span > <img className="list-icons" src={ListIcon} alt="check" /></span> <p>Healthcare Friendly Features + Scalability</p></li>
                  <li className="d-flex"><span > <img className="list-icons" src={ListIcon} alt="check" /></span> <p>Elevated Patient & Provider Telehealth Experience</p></li>
                </ul>
              </div>
            </div>
            <div className="d-flex box flex-column">
              <div className="main d-flex flex-column forcast">
                <h1 className="box-heading forecasted">Forecasted Revenue</h1>
                <div className="bar mx-auto">
                  <Chart data={data} options={options} className="chart" chartType="bar" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Investors;