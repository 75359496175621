import React, { useEffect, useState } from 'react'

//components
import CommonHeader from '../../Platform/Header/CommonHeader';
import Sidebar from '../../Platform/Sidebar/Sidebar';
import SelectTypeHeader from '../../Platform/Header/SelectTypeHeader';
import LaptopComponent from './LaptopComponent';
import LaptopBlackSidebar from '../../Platform/Sidebar/LaptopBlackSidebar/LaptopBlackSidebar';

// redux
import { RootState } from '../../../../../Store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFinishedLogin, setType } from '../../../../../Store/Slicers/OnBoarding/OnBoardingSlicer';

//css
import '../../Platform/Header/CommonHeader.scss'
import '../../../OnBoarding.scss';
import { title } from 'process';

interface Step4Props {
    handleBackButtonClick: () => void; handleCheckoutButtonClick: () => void;
}

const Step4 = ({ handleBackButtonClick, handleCheckoutButtonClick }: Step4Props) => {
    const { isfinishedSaving, selectedType } = useSelector((state: RootState) => state.onBoarding)
    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStartedVisible, setIsStartedVisible] = useState(true)

    const [title, setTitle] = useState<"Standard Platform" | "Customize" | null>(null)

    const handleModalOpen = () => {
        setIsModalOpen(true);
    }
    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    // useEffect(() => {
    //     const handleClickOutside = (event: any) => {
    //         if (isModalOpen && event.target.classList.contains('laptop-model')) {
    //             handleModalClose();
    //         }
    //     };

    //     document.addEventListener('click', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, [isModalOpen]);

    useEffect(() => {
        if (isfinishedSaving && !isModalOpen) {
            setIsStartedVisible(false)
        }
    }, [isfinishedSaving])

    const handleTypeChange = (type: string) => {
        dispatch(setType(type))
    }

    const standardPlatform = () => {
        setTitle("Standard Platform")
        dispatch(setIsFinishedLogin(true))
    }

    const handleButtonClick = () => {
        setTitle("Customize")
        setIsModalOpen(true)
    }

    console.log(title)

    return (
        <div className='w-100'>
            <div className='d-flex onboarding-main-flow'>
                <div className='d-flex flex-column step-4' style={{ gap: "60px" }}>
                    <div className='d-flex flex-column gap-3'>
                        <p className='p-top'>
                            STEP 4
                        </p>
                        {isModalOpen &&
                            <>
                                <div className="modal-backdrop" />
                                <div
                                    className={`modal fade ${isModalOpen ? "show" : ""} laptop-model`}
                                    style={{ display: isModalOpen ? "block" : "none" }}
                                >
                                    <div className="modal-dialog modal-dialog-centered" role="dialog document">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <div className='row laptop-modal-body'>
                                                    <div className="col-xl-9 col-md-8 h-100 p-0">
                                                        <div className="inner-screen outer-height position-relative" id="bg-ring">
                                                            <div className='laptop-notch'></div>
                                                            <div className='image'>

                                                            </div>
                                                            <SelectTypeHeader />
                                                            <CommonHeader />
                                                            <Sidebar ComponentTitle='Customize' />
                                                        </div>

                                                    </div>
                                                    <div className="col-xl-3 col-md-4 p-0 outer-black-sidebar">
                                                        <LaptopBlackSidebar handleModalClose={handleModalClose} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        <div>

                            <h1 className='onboarding-heading'>{isfinishedSaving ? "Great, Let's Take A Look At Your Platform" : "Use Our Standard Platform or Customize Your Own"}</h1>
                            {
                                !isfinishedSaving &&
                                <p className='onboarding-detail' style={{ color: "#3B8FA9" }}>A branded telehealth platform demonstrates innovation, enhances the patient experience, and strengthens trust in your healthcare organization.</p>
                            }
                        </div>
                    </div>

                    <div className='d-flex gap-3 flex-column justify-content-center'>
                        <div className='position-relative'>
                            {
                                isfinishedSaving &&
                                <div className='d-flex justify-content-center gap-4 select-type'>
                                    <p className={`type ${selectedType === "Patient" && 'active-type'}`} onClick={() => handleTypeChange("Patient")}>Patient</p>
                                    <p className={`type ${selectedType === "Provider" && 'active-type'}`} onClick={() => handleTypeChange("Provider")}>Provider</p>
                                    <p className={`type ${selectedType === "Admin" && 'active-type'}`} onClick={() => handleTypeChange("Admin")}>Admin</p>
                                </div>
                            }
                            <div className='d-flex justify-content-center flex-column position-relative'>
                                {
                                    !isfinishedSaving &&
                                    <>
                                        
                                        <div className='d-flex gap-4 step4-two-laptop-screen'>
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Standard Platform" />
                                                <div className='d-flex justify-content-center'>
                                                    <button className='customize-theme-btn' onClick={standardPlatform}>Standard Platform</button>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Customize" />
                                                <div className='d-flex justify-content-center'>
                                                    <button className='customize-theme-btn' onClick={handleModalOpen}>Customize</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex position-absolute' style={{bottom:-2}}>
                                            <button className='onboarding-back main-delete-theme-btn' onClick={handleBackButtonClick}>Back</button>
                                        </div>
                                    </>
                                }
                                {
                                    isfinishedSaving &&
                                    (
                                        title === "Standard Platform" ?
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Standard Platform" />
                                            </div> : <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Customize" />
                                            </div>
                                    )
                                }
                            </div>

                            {
                                isfinishedSaving &&
                                <div className='dot-button-content w-100'>
                                    <div className='three-dots mt-4 d-flex gap-2 justify-content-center '>
                                        <div className={`dots ${selectedType == "Patient" && 'filled'}`}></div>
                                        <div className={`dots ${selectedType == "Provider" && 'filled'}`}></div>
                                        <div className={`dots ${selectedType == "Admin" && 'filled'}`}></div>
                                    </div>
                                    <br />
                                    <div className='mt-2 d-flex justify-content-end p-2 gap-4'>
                                        {
                                            title === "Standard Platform" ? <button className='btn pro-light-btn' style={{ background: "#fff" }} onClick={handleButtonClick}>Try Customizing</button> : <button className='btn pro-light-btn px-4' style={{ background: "#fff" }} onClick={handleButtonClick}>Edit</button>
                                        }
                                        <button className='onboarding-checkout' onClick={handleCheckoutButtonClick}>Confirm and Continue</button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Step4