import axios from "axios";
import { getLocalStorage } from "../Components/Patient/Utils/AuthHandlers";
import { toast } from "react-toastify";
import { logoutHandler } from "../Components/utils";
import { ToastMessage } from "../Components/Common/ToastMessage";

const timeout = process.env.NODE_ENV === "development" ? 300000 : 120000;
const api = axios.create({
  timeout,
  withCredentials: false,
  responseType: "json",
});

api.interceptors.request.use(
  async (config: any) => {
    const authToken = getLocalStorage("userToken");
    if (authToken) {
      config.headers.Authorization =
        config.headers.Authorization || `Token ${authToken}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("🚀 ~ error:", error);
    if (error.message === "The incoming token has expired") {
      ToastMessage("error", "Your Token is Expired Please Login Again to continue");
      logoutHandler();
    }
    switch (error.response.status) {
      case 401:
        ToastMessage("error", "You are not Authorized. Please try again.");
        logoutHandler();
        break;

      default:
        return error;
    }
    return Promise.reject(error);
  }
);

const tokenHeaders = (userToken?: string) => {
  const token = getLocalStorage("userToken");
  const headers: any =
    userToken && token
      ? { headers: { Authorization: `Bearer ${userToken}` } }
      : {};
  return headers;
};

export const get = async (
  url: string,
  userToken?: string,
  queryParams?: Record<string, string | number>
) => {
  const headers = tokenHeaders(userToken);
  // If queryParams are provided, add them to the URL
  if (queryParams) {
    const queryString = new URLSearchParams(queryParams as any).toString();
    url = `${url}?${queryString}`;
  }
  const response = await api.get(url, headers);
  return response;
};

export const post = async (url: string, userToken?: string, data?: any, payloadType?: string) => {
  const headers = tokenHeaders(userToken);
  if (payloadType) {
    headers["Content-Type"] = "multipart/form-data";
  }
  headers["Content-Type"] = "application/json";
  const response = await api.post(url, data, headers);
  return response;
};

export const put = async (url: string, data: any, userToken?: string) => {
  const headers = tokenHeaders(userToken);
  const response = await api.put(url, data, headers);
  return response;
};
// Note: delete is a reserved word
export const remove = async (url: string, userToken?: string) => {
  const headers = tokenHeaders(userToken);
  const response = await api.delete(url, headers);
  return response;
};
