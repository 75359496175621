import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react'
import { formatPhoneNumber, USAStatesCB } from '../../../Components/utils';
import CBSelectState from '../../../Components/Common/CBSelect/CBSelectState';
import { FileUploader } from 'react-drag-drop-files';
import { CloseModelIcon } from '../../../Assets/Svgs';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import images from '../../../Assets/Images';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAdminplan } from '../../../Store/Slicers/commonSlicer';
import { storeImages } from '../../../Store/Thunk/storeProfileData/storeImagesThunk';
import { storeAdminProfileData } from '../../../Store/Thunk/storeProfileData/storeAdminProfileThunk';
import { getLocalStorage } from '../../../Components/Admin/Utils/AuthHandlers';
import { ToastMessage } from '../../../Components/Common/ToastMessage';

interface FileState {
    file: File | null;
    fileName: string | null;
    fileBuffer: string;
}

const AdminFormFlow = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userData = getLocalStorage("userData")
    const authToken = getLocalStorage("userToken")
    const email = getLocalStorage("userEmail")

    const userId = userData.sub


    const fileTypes = ["JPEG", "PNG", "JPG"];

    const [profilePicture, setProfilePicture] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: "",
    });

    const initialValues: any = {
        firstname: "",
        lastname: "",
        Address: "",
        SuiteNumber: "",
        City: "",
        State: "",
        ZipCode: "",
        Phone: "",
        Fax: "",
        Email: email,
    };
    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('First Name is required'),
        lastname: Yup.string().required('Last Name is required'),
        Address: Yup.string(),
        SuiteNumber: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Suite Number must be alphanumeric'),
        City: Yup.string(),
        State: Yup.string().test('State', 'Invalid State', value => {
            if (!value) return true; // Allow empty values
            const validStates = USAStatesCB.map(state => state.value);
            const validStatesLAbel = USAStatesCB.map(state => state.label?.toUpperCase());
            if (value?.length === 2) {
                return validStates.includes(value);
            }
            else if (value?.length >= 2) {
                if (validStatesLAbel.includes(value?.toUpperCase())) {
                    return validStatesLAbel.includes(value?.toUpperCase());

                }
                else {
                    return false;
                }
            }

        }),
        ZipCode: Yup.string()
            .matches(/^\d{5}(-\d{4})?$/, 'ZIP Code must be in the format XXXXX OR XXXXX-XXXX'),
        Phone: Yup.string().required('Phone number is required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number must be in the format (XXX) XXX-XXXX'),


        Fax: Yup.string()
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Fax number must be in the format (XXX) XXX-XXXX'),
        Email: Yup.string(),
    });

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        toast.loading("Please Wait...")
        const ImagePayload: any = {
            user_id: userId,
            type: 'admin',
            profilePicture: {
                imageName: profilePicture.fileName,
                buffer: profilePicture.fileBuffer,
            }
        };

        const data: any = {
            first_name: values.firstname || null,
            last_name: values.lastname || null,
            profile_image_path: null,
            address: values.Address || null,
            suite: values.SuiteNumber || null,
            city: values.City || null,
            state: values.State || null,
            zip_code: values.ZipCode || null,
            phone: values.Phone || null,
            fax: values.Fax || null,
            email: values.Email
        };
        try {
            // let userId = userData.sub;
            // Dispatch the thunk and await the result
            let result: any;

            if (profilePicture.fileName) {
                result = await dispatch(storeImages({ authToken, ImagePayload: ImagePayload, userId, profilepayload: data, type: 'admin' }));
            } else {
                result = await dispatch(storeAdminProfileData({ authToken, data, userId }));
            }

            // Handle the result if needed
            ToastMessage("success", "Data saved successfully");
            if (result.payload && result.payload.status === 200) {
                toast.dismiss()
                dispatch(setAdminplan('clinic'))
                navigate("/admin/homelisting")
            } else {
                // Handle errors
                console.error('Error in saving data:', result.payload.message);
                toast.dismiss()
                ToastMessage("error", "Error in saving Data");
            }
            toast.dismiss();
        } catch (error) {
            // Handle any errors that occur during dispatch
            console.log('Error while dispatching the thunk:', error);
            toast.dismiss()
            ToastMessage("error", "Error in saving Data");
        }
        dispatch(setAdminplan("clinic"))
        navigate("/admin/homelisting")

        setSubmitting(false);
    };

    const handleChange = (file: File, setFileState: React.Dispatch<React.SetStateAction<FileState>>) => {
        setFileState((prevFileState) => ({
            ...prevFileState,
            file: file,
            fileName: file.name,
        }));

        let reader = new FileReader();
        reader.onloadend = function () {
            setFileState((prevFileState) => ({
                ...prevFileState,
                fileBuffer: reader.result as string,
            }));
        };
        reader.readAsDataURL(file);
    };

    return (
        <div className="row">
            <h1 className="waiting-header mb-4">Let’s complete your profile</h1>
            <div className="col-lg-12 col-xl-12">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 pe-2">
                        <div className="admin profile-formbox admin-profile-formbox">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validationSchema}
                            >
                                {(formik: any) => (
                                    <Form className="h-100 d-flex flex-column justify-content-between ">
                                        <h3
                                            className="sm_card_title d-flex justify-content-between pb-3"
                                            style={{ borderBottom: "1px solid #dadada" }}
                                        >
                                            my Account Information
                                            <div className="d-flex gap-2">
                                                <button
                                                    className="yes-theme-btn"
                                                    type="submit"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </h3>
                                        <div className="row  admin-profile" style={{ height: "calc(100vh - 490px" }}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group flex-column d-flex position-relative">
                                                        <img src={CloseModelIcon} style={{ width: "20px" }} alt="" className={`position-absolute cursor-pointer z-1 remove-image close-model-icon ${!profilePicture?.fileName ? "d-none" : ""}`} height={"14px"} width={"14px"}
                                                            onClick={() => setProfilePicture({
                                                                file: null,
                                                                fileName: null,
                                                                fileBuffer: "",
                                                            })} />
                                                        <div className={`fileuploadbox ${profilePicture.file ? "uploaded" : ""}`} style={{ width: "100%" }}>
                                                            <FileUploader
                                                                multiple={false}
                                                                handleChange={(file: any) => handleChange(file, setProfilePicture)}
                                                                name="file"
                                                                types={fileTypes}
                                                                label="Upload or drop a file here"
                                                                className="Dropzone"
                                                                children={
                                                                    <div className="uploader-box">
                                                                        <p className="text-dark p-0" style={{ fontSize: "14px" }}>Upload your photo</p>
                                                                        <p className="upload-text">
                                                                            Drag file here to upload
                                                                        </p>
                                                                        <img src={profilePicture?.fileBuffer ? profilePicture?.fileBuffer : images.DefaultImage} alt="" height={30} />

                                                                        {profilePicture.fileName ? (
                                                                            <p className="uploadedfiletext">{`${profilePicture?.fileName?.slice(0, 40)}...`}</p>
                                                                        ) : (
                                                                            <button className="btn select-filebtn">
                                                                                Select file
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <div className="col-input">
                                                                    <div className="col-label">
                                                                        <label htmlFor="firstname" className="form-label">
                                                                            First Name <span className="text-danger">*</span>
                                                                        </label>
                                                                    </div>
                                                                    <Field
                                                                        type="text"
                                                                        className={`form-control ${formik.touched.firstname && formik.errors.firstname ? 'error-border' : ''}`}
                                                                        name="firstname"
                                                                    />
                                                                    <p><ErrorMessage name="firstName" /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <div className="col-input">
                                                                    <div className="col-label">
                                                                        <label htmlFor="lastname" className="form-label">
                                                                            Last Name <span className="text-danger">*</span>
                                                                        </label>
                                                                    </div>
                                                                    <Field
                                                                        type="text"
                                                                        className={`form-control ${formik.touched.lastname && formik.errors.lastname ? 'error-border' : ''}`}
                                                                        name="lastname"
                                                                    />
                                                                    <p><ErrorMessage name="lastname" /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-12">
                                                <div className="form-group">
                                                    <div className="col-input">
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${formik.touched.Address && formik.errors.Address ? 'error-border' : ''}`}
                                                            name="Address"
                                                        />
                                                    </div>
                                                    <div className="col-label">
                                                        <label htmlFor="Address" className="form-label">
                                                            Address
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-12">
                                                <div className="form-group">
                                                    <div className="col-input">
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${formik.touched.SuiteNumber && formik.errors.SuiteNumber ? 'error-border' : ''}`}
                                                            name="SuiteNumber"
                                                        />
                                                    </div>
                                                    <div className="col-label">
                                                        <label htmlFor="SuiteNumber" className="form-label">
                                                            Suite Number
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-10 col-md-6 col-xl-4">
                                                <div className="form-group">
                                                    <div className="col-input">
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${formik.touched.City && formik.errors.City ? 'error-border' : ''}`}
                                                            name="City"
                                                        />
                                                    </div>
                                                    <div className="col-label">
                                                        <label htmlFor="City" className="form-label">
                                                            City
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-10 col-md-6 col-xl-4">
                                                <div className="form-group">
                                                    <div className="col-input">
                                                        <CBSelectState options={USAStatesCB} seletedItem={formik.values.State} onChange={(option: string) => {
                                                            if (option?.length === 2) {
                                                                formik.setFieldValue('State', option)
                                                            } else {
                                                                const validStatesLAbel = USAStatesCB.filter(state => state.label?.toUpperCase() === option?.toUpperCase());
                                                                if (validStatesLAbel?.length > 0) {
                                                                    formik.setFieldValue('State', validStatesLAbel[0]?.value)
                                                                } else {
                                                                    formik.setFieldValue('State', option)
                                                                }
                                                            }
                                                        }} className={`${formik.touched.State && formik.errors.State ? 'error-border' : ''} w-100 m-0 StateDropdown-small-border-class`} placeholder='Select' />
                                                    </div>
                                                    <div className="col-label">
                                                        <label htmlFor="State" className="form-label">
                                                            State
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-10 col-md-6 col-xl-4">
                                                <div className="form-group">
                                                    <div className="col-input">
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${formik.touched.ZipCode && formik.errors.ZipCode ? 'error-border' : ''}`}
                                                            name="ZipCode"
                                                            onChange={(e: any) => {
                                                                const formattedValue = e.target.value;
                                                                formik.setFieldValue('ZipCode', formattedValue);
                                                            }}
                                                        />
                                                        <p className="formik-validation-red-message">
                                                            {formik.validateOnBlur && formik.errors.ZipCode ? formik.errors.ZipCode : null}
                                                        </p>
                                                    </div>
                                                    <div className="col-label">
                                                        <label htmlFor="ZipCode" className="form-label">
                                                            Zip Code
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="form-profile-title">Contact Details</h3>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.Phone && formik.errors.Phone ? 'error-border' : ''}`}
                                                                name="Phone"
                                                                onChange={(e: any) => {
                                                                    const formattedValue = formatPhoneNumber(e.target.value);
                                                                    formik.setFieldValue('Phone', formattedValue);
                                                                }}
                                                            />
                                                            <p className="formik-validation-red-message">
                                                                {formik.validateOnBlur && formik.errors.Phone ? formik.errors.Phone : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="Phone" className="form-label">
                                                                Phone <span className="text-danger">*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.Fax && formik.errors.Fax ? 'error-border' : ''}`}
                                                                name="Fax"
                                                                onChange={(e: any) => {
                                                                    const formattedValue = formatPhoneNumber(e.target.value);
                                                                    formik.setFieldValue('Fax', formattedValue);
                                                                }}
                                                            />
                                                            <p className="formik-validation-red-message">
                                                                {formik.validateOnBlur && formik.errors.Fax ? formik.errors.Fax : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="Fax" className="form-label">
                                                                Fax
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.Email && formik.errors.Email ? 'error-border' : ''}`}
                                                                name="Email"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="Email" className="form-label">
                                                                Email
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminFormFlow