import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { getLocalStorage, setLocalStorage } from "../Components/Patient/Utils/AuthHandlers";

// redux
import { useDispatch } from "react-redux";
import { fetchUserMessageData } from "../Store/Thunk/getUserMessages/userMessagesThunk";
import { ToastMessage } from "../Components/Common/ToastMessage";

type WebSocketContextType = {
  socket: WebSocket | null;
  isConnected: boolean;
  connectWebSocket: (userId: string) => void;
  disconnectWebSocket: () => void;
};

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export function useWebSocket() {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
}

type WebSocketProviderProps = {
  children: ReactNode;
};

export function WebSocketProvider({ children }: WebSocketProviderProps) {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(getLocalStorage("IsConnect") || false);
  const SOCKET_URL: string | undefined = process.env.REACT_APP_SOCKET_SERVER_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getLocalStorage("userToken");
    const LocalData = getLocalStorage("userData");

    if (token.length) {
      const newSocket = new WebSocket(`${SOCKET_URL}?user_id=${LocalData.sub}`);
      newSocket.addEventListener("open", () => {
        console.log('first>>', LocalData.sub)
        setIsConnected(true);
        setLocalStorage("IsConnect", 'true');
      });

      newSocket.addEventListener("message", (event) => {
        const receivedMessage = JSON.parse(event.data);
        console.log('first>> :', receivedMessage);
        const message = receivedMessage.message;
        ToastMessage("info", message);
        let userId = LocalData.sub;
        dispatch(fetchUserMessageData(userId));
      });

      newSocket.addEventListener("close", () => {

        setIsConnected(false);
        setLocalStorage("IsConnect", 'false');
      });

      newSocket.addEventListener("error", (event) => {

        console.error("WebSocket error:", event);
      });

      setSocket(newSocket);

      return () => {
        if (newSocket.readyState === WebSocket.OPEN) {
          newSocket.close();
        }
      };
    }
  }, [SOCKET_URL]);

  const connectWebSocket = (userId: string) => {
    if (SOCKET_URL) {
      if (socket == null && !isConnected) {
        const newSocket = new WebSocket(`${SOCKET_URL}?user_id=${userId}`);
        newSocket.addEventListener("open", () => {
          console.log('first>>', 'connected2')

          setIsConnected(true);
          setLocalStorage("IsConnect", 'true');
        });

        newSocket.addEventListener("close", () => {

          setIsConnected(false);
          setLocalStorage("IsConnect", 'false');
        });

        setSocket(newSocket);
      }
    }
  };

  const disconnectWebSocket = () => {
    if (isConnected && socket) {
      setLocalStorage("IsConnect", 'false');
      socket.close();
      setIsConnected(false);
    }
  };

  useEffect(() => {
    return () => {
      disconnectWebSocket();
    };
  }, []);

  useEffect(() => {
    console.log("Is Socket connected ?", isConnected);
    const LocalData = getLocalStorage("userData");
    if (!isConnected && LocalData.sub) {
      connectWebSocket(LocalData.sub);
    }
  }, [isConnected]);

  const contextValue: WebSocketContextType = {
    socket,
    isConnected,
    connectWebSocket,
    disconnectWebSocket,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
}