import React from "react";
// hook
import { usePricing } from "./usePricing";
// components
import Monthly from "./Components/Monthly";
import Yearly from "./Components/Yearly";
import Footer from "../Footer/Footer";
import SignInHeader from "../../Components/Common/SignInHeader/SignInHeader";

// asstes
import images from "../../Assets/Images";
import "./Pricing.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import OnBoarding from "../OnBoarding/OnBoarding";

const Pricing = () => {
  const {
    pricingPlan,
    handleButtonClick,
    faqItems,
    openAccordionIndex,
    handleAccordionToggle,
    setIsOnboarding,
    isOnboarding
  } = usePricing();
  return (
    <div>
      {
        !isOnboarding ? (<>
          <div className="pricing-page">
            <div className="first first-bg">
              <img src={images.PricingBg} alt="" />
            </div>
            <SignInHeader />
            <div className={!isOnboarding ? "our" : "d-none"}>
              <h1 className="pricing-header">Our Pricing</h1>
              <p className="text-center mt-2 info">
                At CentiBlick we believe in transparent affordable pricing and
                customer service that exceeds expectations.
              </p>
            </div>
            <div className="second second-bg">
              <img src={images.PricingBg} alt="" />
            </div>
          </div>

          <div className="container pricing-plans-div">
            <div className="d-flex justify-content-center flex-column plan-details">
              <div className="d-flex justify-content-center month-btn">
                <div className="buttons p-3">
                  <button
                    className={`month ${pricingPlan === "month" ? "active-duration" : ""}`}
                    onClick={() => handleButtonClick('month')}
                  >
                    Monthly
                  </button>
                  <button
                    className={`month d-flex align-items-center gap-2 ${pricingPlan === "year" ? "active-duration" : ""}`}
                    onClick={() => handleButtonClick('year')}
                  >
                    Yearly
                    <span>GET 2 MONTHS FREE</span>
                  </button>
                </div>
              </div>
              <div className="year monthly">
                {pricingPlan === "month" ? <Monthly setIsOnboarding={setIsOnboarding} /> : <Yearly setIsOnboarding={setIsOnboarding} />}
              </div>
              {/* <div className="year monthly">{isMonthly ? <Monthly /> : <Yearly />}</div> */}
            </div>
            <div className="d-flex bottom-content">
              <img src={images.PricingSideImage} alt="img" />
              <div className="content">
                <div className="main-content">
                  <h1>Frequently asked questions</h1>
                  <p className="mt-3">
                    Everything you need to know about the product and billing.
                  </p>
                </div>
                <div className="side-content accordion mt-5" id="faqAccordion">
                  {faqItems.map((item, index) => (
                    <div key={index}>
                      <h2
                        className="accordion-header d-flex justify-content-between"
                        id={`faqHeading${index}`}
                      >
                        <div className="que">{item.question}</div>
                        <button
                          data-bs-toggle="collapse"
                          data-bs-target={`#faqCollapse${index}`}
                          aria-expanded={openAccordionIndex === index}
                          aria-controls={`faqCollapse${index}`}
                          onClick={() => handleAccordionToggle(index)}
                        >
                          <FontAwesomeIcon
                            icon={openAccordionIndex === index ? faMinus : faPlus}
                            style={{ color: "#3B8FA9" }}
                            className="icon"
                          />
                        </button>
                      </h2>
                      <div
                        id={`faqCollapse${index}`}
                        className={`accordion-collapse collapse ${openAccordionIndex === index ? "show" : ""
                          }`}
                        style={openAccordionIndex === 0 ? { height: "fit-content" } : {}}
                        aria-labelledby={`faqHeading${index}`}
                        data-bs-parent="#faqAccordion"
                      >
                        <div className="accordion-body px-0">
                          {
                            openAccordionIndex === 0 ?
                              <>
                                <h2 className="pb-4">5 Essential Questions to Ensure Your Telehealth Platform is HIPAA Compliant</h2>
                                <ol className="ps-4">
                                  <li>Does your telehealth platform ensure end-to-end transmission and session encryption for all communications and patient data?</li>
                                  <li>Are patient logins and provider authentication required to access telehealth sessions?</li>
                                  <li>Does your telehealth platform comply with HIPAA's Security Rule regarding data storage and transmission?</li>
                                  <li>What steps does the platform take to verify the identity and credentials of both providers and patients?</li>
                                  <li>Has your telehealth platform been updated to reflect the end of the COVID-19 Public Health Emergency, and are we compliant with current HHS guidelines?</li>
                                </ol>
                                <p className="pt-3">Click here for the Telehealth Compliance Whitepaper</p>
                              </>
                              :
                              item.answer
                          }
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="text-center bottom-heading">
            <h1>Empower your patients and providers today!</h1>
            <div >
              <input type="text" placeholder="Your email address" />
              <button className="big-btn">Get Started Now</button>
            </div>
          </div> */}
          <Footer />
        </>) : (
          <OnBoarding />
        )
      }
    </div>
  );
};

export default Pricing;
